import React, { useState } from 'react';
import './sidebar.css';
import Logo from '../../assets/stealth-inventory-logo.webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip, faChartLine, faChevronDown, faChevronUp, faGears, faWarehouse, faCircleQuestion, faRocket, faTerminal, faCode } from '@fortawesome/pro-solid-svg-icons';
import { faChevronsLeft, faChevronsRight } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from 'react-router-dom';

import warp10logo from '../../assets/warp10-logo.webp';

const toUrlSlug = (str) => {
    return encodeURIComponent(str.toLowerCase().replace(/ /g, "_"));
};

const SidebarHeader = ({collapsed,setCollapsed}) => {

    const toggleCollapse = (function(){
        setCollapsed(!collapsed);
    });

    return (
        <div className="header">
            <div className="container">
                <div className="logo">
                    <img alt="STEALTH LOGO" src={Logo}/>
                </div>
                <FontAwesomeIcon className="clickable collapse" onClick={toggleCollapse} icon={collapsed ? faChevronsRight : faChevronsLeft} />
            </div>
        </div>
    );
}

const SidebarDashboardButton = () => {
    return (
        <Link to="/">
            <div className="clickable dashboard">
                <div className="container">
                    <span>DASHBOARD</span>
                    <FontAwesomeIcon icon={faGrip} />
                </div>
            </div>
        </Link>
    );
}

const SidebarMenuSubmenuItem = ({menuName,submenu}) => {
    const menuSlug = toUrlSlug(menuName);
    const submenuSlug = toUrlSlug(submenu.title);
    const url = `/${menuSlug}/${submenuSlug}`;

    return (
        <div className="submenu">
            <div className="linebox">
                <div className="corner-line"></div>
            </div>
            <Link to={url}>
            <span className="clickable">{submenu.title}</span>
            </Link>
        </div>
    );
}

const SidebarMenuItem = ({icon, title, submenus, collapsed, setCollapsed, titleImg}) => {
    const [closed, setClosed] = useState(true);
    const submenusExist = Array.isArray(submenus) && (submenus.length>0);
    const submenusHeight = submenusExist ? (40*submenus.length)+'px' : '0px';

    const toggleClose = (function(){
        if (collapsed) {
            setCollapsed(false);
            setClosed(false);
        } else {
            setClosed(!closed);
        }
    });

    return (
        <div className="menu">
            <div onClick={toggleClose} className={`clickable control ${closed ? '' : 'open'}`}>
                <div className="title">
                    <FontAwesomeIcon className="icon" icon={icon} title={title} />
                    {titleImg ? <img alt="" src={titleImg}/>: <span>{title}</span>}
                </div>
                <FontAwesomeIcon className="close" style={{display:(submenusExist?'':'none')}} icon={(closed) ? faChevronDown : faChevronUp} />
            </div>
            <div className={closed ? 'submenus closed' : 'submenus'} style={{height:submenusHeight}}>
                {submenusExist && submenus.map((submenu,index) =>
                    <SidebarMenuSubmenuItem key={`${title}-${index}`} menuName={title} submenu={submenu}/>
                )}
            </div>
        </div>
    );
}

const SidebarMenus = ({collapsed,setCollapsed}) => {
    return (
        <div className="menus">
            <SidebarMenuItem 
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                icon={faWarehouse} 
                title="Inventory"
                key="Inventory"
                submenus = {[
                    {title: 'Group Inventories'},
                ]}
            />
            <SidebarMenuItem 
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                icon={faRocket} 
                title="Warp10"
                key="Warp10"
                titleImg={warp10logo}
                submenus = {[
                ]}
            />
            <SidebarMenuItem 
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                icon={faChartLine} 
                title="Analytics"
                key="Analytics"
                submenus = {[
                ]}
            />
            <SidebarMenuItem 
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                icon={faGears} 
                title="Admin"
                key="Admin"
                submenus = {[
                ]}
            />
            <SidebarMenuItem 
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                icon={faTerminal} 
                title="Command Center"
                key="Command Center"
                submenus = {[
                    {title: 'Create Group'},
                    {title: 'Create Inventory'},
                    {title: 'Create Location'},
                    {title: 'Create User'},
                    {title: 'All Groups'},
                    {title: 'All Locations'},
                    {title: 'All Users'},
                    {title: 'Feed Setup'},
                    {title: 'Open Tickets'},
                    {title: 'Data Catalog Import'},
                ]}
            />
            <SidebarMenuItem 
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                icon={faCircleQuestion} 
                title="Help"
                key="Help"
                submenus = {[
                ]}
            />
        </div>
    );
}

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className={collapsed ? 'sidebar collapsed' : 'sidebar'}>
            <SidebarHeader collapsed={collapsed} setCollapsed={setCollapsed}/>
            <SidebarDashboardButton/>
            <SidebarMenus collapsed={collapsed} setCollapsed={setCollapsed}/>
        </div>
    );
}

export default Sidebar;