import axios from 'axios';


export default class DataCatalog {
    constructor(auth,environment) {
        this.auth = auth;
        this.environment = environment;
    }

    async post(formData) {
        try {
            const response = await axios.post(`${this.environment.domain}${this.environment.api.endpoint}/datacatalog`,
            formData,
            {
                headers: {
                    'AccessToken': this.auth.tokens.AccessToken,
                }
            });

            const { data: responseData } = response;
            
            if (!response.data || !response.data.status) {
                console.log('Internal error. Please try again later.');
                return;
            }

            let status = response.data.status;
            let message = response.data.message;

            if (status == 'success') {
                const rv_key = responseData.message.rv_key;
                return rv_key;
            } else {
                throw message;
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            throw error;
        }   
    }
}