import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { faSquare as faSolidSquare, faSquareCheck as faSolidSquarecheck, faSort } from '@fortawesome/pro-solid-svg-icons';
import { faSortDown, faSortUp } from '@fortawesome/pro-duotone-svg-icons';
import './table.css';

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
};

const Table = ({ columns, data, setSelectedRows }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortedColumns,setSortedColumns] = useState([]);

  useEffect(() => {
    const selectedDataRows = checkedRows.map(index => data[index]);
    setSelectedRows(selectedDataRows);
  }, [checkedRows]);

  useEffect(() => {
    setIsLoading(!(data.length > 0));
    // setCheckedRows([]); causes a problem, maybe not necissary
  }, [data]);

  const handleHeaderCheckbox = () => {
    const isChecked = !selectAll;
    setSelectAll(isChecked);
    let updatedCheckedRows = [];
    if (isChecked) {
      updatedCheckedRows = data.map((_, i) => i);
    }
    setCheckedRows(updatedCheckedRows);
  };

  const handleRowCheckbox = (rowIndex) => {
    const isChecked = !checkedRows.includes(rowIndex);
    let updatedCheckedRows = [...checkedRows];

    if (isChecked) {
      updatedCheckedRows.push(rowIndex);
    } else {
      updatedCheckedRows = updatedCheckedRows.filter(index => index !== rowIndex);
    }
    setCheckedRows(updatedCheckedRows);

    setSelectAll(updatedCheckedRows.length === data.length);
  };

  const handleSortClick = (index) => {
    let currentSortedColumns = [...sortedColumns];

    switch (currentSortedColumns[index]) {
      default:
        currentSortedColumns[index] = "asc";
        break;
      case 'asc':
        currentSortedColumns[index] = "dsc";
        break;
      case 'dsc':
        delete currentSortedColumns[index];
        break;
    }

    setSortedColumns(currentSortedColumns);
  }

  const getSortedData = () => {
    let sortedData = [...data];
    sortedColumns.forEach((sortOrder, index) => {
      if (sortOrder) {
        sortedData.sort((a, b) => {
          let columnKey = columns[index].accessor;
          let valueA = isObject(a[columnKey]) ? a[columnKey].value : a[columnKey];
          let valueB = isObject(b[columnKey]) ? b[columnKey].value : b[columnKey];

          if (sortOrder === 'asc') {
            return valueA < valueB ? -1 : 1;
          } else if (sortOrder === 'dsc') {
            return valueA > valueB ? -1 : 1;
          }
          return 0;
        });
      }
    });
    return sortedData;
  }

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th onClick={handleHeaderCheckbox}>
              <FontAwesomeIcon className="clickable" icon={selectAll ? faSolidSquarecheck : faSolidSquare} />
            </th>
            {columns.map((col, index) => {
              let sortedState = sortedColumns[index];
              return (<th onClick={()=>{handleSortClick(index)}} key={index}>{col.header.toUpperCase()} 
                {!sortedState&&<FontAwesomeIcon icon={faSort} style={{marginLeft:'5px'}}/>}
                {sortedState==="asc"&&<FontAwesomeIcon icon={faSortUp} style={{marginLeft:'5px'}}/>}
                {sortedState==="dsc"&&<FontAwesomeIcon icon={faSortDown} style={{marginLeft:'5px'}}/>}
              </th>)
            })}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length + 1}>
                <div className="loading-container">
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              </td>
            </tr>
          ) : (
            getSortedData().map((item, rowIndex) => (
              <tr key={rowIndex}>
                <td onClick={() => handleRowCheckbox(rowIndex)}>
                  <FontAwesomeIcon className="clickable" icon={checkedRows.includes(rowIndex) ? faSquareCheck : faSquare} />
                </td>
                {columns.map((col, colIndex) => {
                  let cell = item[col.accessor];
                  if (isObject(cell)) {
                    return (<td key={colIndex}>{item[col.accessor].display}</td>);
                  }
                  return (<td key={colIndex}>{item[col.accessor]}</td>);
                })}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );  
};

export default Table;
