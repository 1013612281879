import axios from 'axios';

export default class Inventory {
    constructor(auth,environment) {
        this.auth = auth;
        this.environment = environment;
    }

    async get(inventory_key) {
        try {
            const response = await axios.get(`${this.environment.domain}${this.environment.api.endpoint}/inventory/${inventory_key}`, {
                headers: {
                    'AccessToken': this.auth.tokens.AccessToken,
                }
            });

            const { data: responseData } = response;

            if (!responseData || !responseData.data) { 
                console.error("Unexpected response format");
                return [];
            }

            const dataArray = responseData.data;
        
            return dataArray;
        } catch (error) {
            console.error("Error fetching data: ", error);
            throw error;
        }   
    }

    async post(name, group_key, type, fields) {
        try {
            const response = await axios.post(`${this.environment.domain}${this.environment.api.endpoint}/inventory`,
            {
                'name': name,
                'group_key': group_key,
                'type': type,
                'fields': fields,
            },
            {
                headers: {
                    'AccessToken': this.auth.tokens.AccessToken,
                }
            });

            if (!response.data || !response.data.status) {
                console.log('Internal error. Please try again later.');
                return;
            }

            let status = response.data.status;
            let message = response.data.message;

            if (status == 'success') {
                let key = response.data.key;
                console.log(response.data.key);
                return key;
            } else {
                throw message;
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            throw error;
        }   
    }
}