import axios from 'axios';


export default class Inventories {
    constructor(auth,environment) {
        this.auth = auth;
        this.environment = environment;
    }

    async get() {
        try {
            const response = await axios.get(`${this.environment.domain}${this.environment.api.endpoint}/inventories`, {
                headers: {
                    'AccessToken': this.auth.tokens.AccessToken,
                }
            });
            
            const { data: responseData } = response;

            if (!responseData || !responseData.data) { 
                console.error("Unexpected response format");
                return [];
            }

            const dataArray = Object.values(responseData.data);
        
            return dataArray;
        } catch (error) {
            console.error("Error fetching data: ", error);
            throw error;
        }   
    }
}
