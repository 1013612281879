import React,{useState,useEffect} from 'react';
import { VictoryPie, VictoryTheme, VictoryChart, VictoryAxis, VictoryLegend, VictoryLine, VictoryContainer } from 'victory';

import classa from './demophotos/class-a.png';
import classb from './demophotos/class-b.png';
import classc from './demophotos/class-c.png';
import fifthwheel from './demophotos/fifth-wheel.png';
import toyhauler from './demophotos/toy-haulers.png';
import traveltrailer from './demophotos/travel-trailer.png';

import './dashboard.css';

const VictoryProgressGauge = ({ percent, title, negative }) => {
    // Determine color based on the percent value
    const getColor = (percent) => {
        if (negative) {
            if (percent <= 25) return 'var(--secondary-color)';
            if (percent <= 50) return 'yellow';
            return 'red';
        }
        if (percent <= 15) return 'red';
        if (percent <= 40) return 'yellow';
        return 'var(--secondary-color)';
    };

    const padding = 15;
    const strokeWidth = 3;

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <svg viewBox="0 0 400 400" style={{ width: '100%', height: '100%' }}>
            <VictoryPie
                standalone={false}
                animate={{ duration: 1000 }}
                data={[
                    { x: 1, y: percent, label: '' },
                    { x: 2, y: 100 - percent, label: '' }
                ]}
                innerRadius={({ radius }) => radius - 25}
                // cornerRadius={({ datum }) => (datum.y > 0 ? 10 : 0)}
                labels={() => null}
                style={{
                    data: {
                        fill: ({ datum }) => (datum.x === 1 ? getColor(percent) : 'white'),
                        stroke: 'white',
                        strokeWidth: strokeWidth
                    }
                }}
                startAngle={-90}
                endAngle={90}
                padding={padding}
            />
        </svg>
        <div style={{
            position: 'absolute',
            top: '35%', left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
        }}>
            <span className="title">{title}</span><br/>
            <span style={{ fontSize: '1.5em' }}>{`${percent}%`}</span>
        </div>
        </div>
    );
};

// Function to generate test data
const generateTestData = () => {
    const data = [];
    let lastNew = 50; // Starting point for new units
    let lastUsed = 50; // Starting point for used units

    for (let i = 9; i >= 0; i--) {
        const day = new Date();
        day.setDate(day.getDate() - i);

        // Create a small fluctuation around the last value
        lastNew += (Math.random() - 0.5) * 10; // Fluctuate by up to 5
        lastUsed += (Math.random() - 0.5) * 10; // Fluctuate by up to 5

        // Ensure the new values stay within the 0-100 range
        lastNew = Math.max(0, Math.min(100, lastNew));
        lastUsed = Math.max(0, Math.min(100, lastUsed));

        data.push({
            day,
            new: Math.floor(lastNew),
            used: Math.floor(lastUsed),
        });
    }
    return data;
};
  
const LineGraph = () => {
    const testData = generateTestData();

    // Process data for Victory
    const newData = testData.map(point => ({ x: point.day, y: point.new }));
    const usedData = testData.map(point => ({ x: point.day, y: point.used }));

    return (
        <div style={{ width: '100%', height: '100%' }}>
        <VictoryChart
            containerComponent={<VictoryContainer responsive={true} />}
            theme={VictoryTheme.material}os
            
            scale={{ x: "time" }}
        >
            <VictoryLegend
                orientation="horizontal"
                gutter={20}
                style={{ title: { fontSize: 10 } }}
                data={[
                    { name: "New Units", symbol: { fill: "var(--secondary-color)" } },
                    { name: "Used Units", symbol: { fill: "white" } }
                ]}
            />
            <VictoryAxis
                tickFormat={(x) => `${x.getMonth() + 1}/${x.getDate()}`}
                style={{
                    tickLabels: { fill: "white", color:'white', fontSize: 10, padding: 5, angle: 45, verticalAnchor: 'middle', textAnchor:'start' },
                    axisLabel: { fill: "white", fontSize: 12, padding: 30 },
                }}
            />
            <VictoryAxis dependentAxis style={{
                axisLabel: { fill: "white", fontSize: 12, padding: 30 },
            }} />

            <VictoryLine
                data={newData}
                style={{ data: { stroke: "var(--secondary-color)" } }}
            />

            <VictoryLine
                data={usedData}
                style={{ data: { stroke: "white" } }}
            />
        </VictoryChart>
        </div>
    );
};

const NewVsUsedGraph = () => {
    return (
        <div className="new-used-graph">
            <LineGraph/>
        </div>
    );
}

const UnitTypeData = () => {
    return (
        <div className="unittypedata">
            <div className="unit">
                <div className="icon-container">
                    <img src={classa}/>
                </div>
                <div className="metrics-container">
                    <h2>Class A</h2>
                    <span>{Math.floor(Math.random() * 35) + 1} New</span>
                    <span>{Math.floor(Math.random() * 35) + 1} Used</span>
                </div>
            </div>
            <div className="unit">
                <div className="icon-container">
                    <img src={classc}/>
                </div>
                <div className="metrics-container">
                    <h2>Class C</h2>
                    <span>{Math.floor(Math.random() * 35) + 1} New</span>
                    <span>{Math.floor(Math.random() * 35) + 1} Used</span>
                </div>
            </div>
            <div className="unit">
                <div className="icon-container">
                    <img src={classb}/>
                </div>
                <div className="metrics-container">
                    <h2>Class B</h2>
                    <span>{Math.floor(Math.random() * 35) + 1} New</span>
                    <span>{Math.floor(Math.random() * 35) + 1} Used</span>
                </div>
            </div>
            <div className="unit">
                <div className="icon-container">
                    <img src={traveltrailer}/>
                </div>
                <div className="metrics-container">
                    <h2>Travel Trailer</h2>
                    <span>{Math.floor(Math.random() * 35) + 1} New</span>
                    <span>{Math.floor(Math.random() * 35) + 1} Used</span>
                </div>
            </div>
            <div className="unit">
                <div className="icon-container">
                    <img src={fifthwheel}/>
                </div>
                <div className="metrics-container">
                    <h2>Fifth Wheel</h2>
                    <span>{Math.floor(Math.random() * 35) + 1} New</span>
                    <span>{Math.floor(Math.random() * 35) + 1} Used</span>
                </div>
            </div>
            <div className="unit">
                <div className="icon-container">
                    <img src={toyhauler}/>
                </div>
                <div className="metrics-container">
                    <h2>Toy Hauler</h2>
                    <span>{Math.floor(Math.random() * 35) + 1} New</span>
                    <span>{Math.floor(Math.random() * 35) + 1} Used</span>
                </div>
            </div>
        </div>
    )
}

const ProgressGauge = ({title, negative}) => {
    const [number, setNumber] = useState(Math.floor(Math.random() * 101));

    useEffect(() => {
      const fluctuate = () => {
        const change = Math.floor(Math.random() * 5) + 1;
        const positiveOrNegative = Math.random() < 0.5 ? -1 : 1;
        let newNumber = number + change * positiveOrNegative;
        newNumber = Math.min(100, Math.max(0, newNumber));
        setNumber(newNumber);
      };
  
      const interval = Math.random() * (5000 - 2000) + 2000;
      const timer = setTimeout(fluctuate, interval);
  
      return () => clearTimeout(timer);
    }, [number]);

    return (
        <div className="gauge-container">
            <div className="gauge">
                <VictoryProgressGauge percent={number} title={title} negative={negative}/>
            </div>
        </div>
    );
}

const formatDate = (date) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  
  const LogList = ({ list }) => {
    // Function to generate timestamps
    const generateTimestamps = () => {
      let currentTime = new Date();
      return list.map(() => {
        // Subtract a random amount of time between 1 and 5 minutes for the first timestamp
        const minutesToSubtract = 1 + Math.random() * 4;
        currentTime.setMinutes(currentTime.getMinutes() - minutesToSubtract);
  
        // For the subsequent timestamps, subtract additional random time between 1 and 20 minutes
        currentTime = new Date(currentTime.getTime() - (1 + Math.random() * 19) * 60000);
        return formatDate(currentTime);
      });
    };
  
    // Generate timestamps once for the entire list to maintain descending order
    const timestamps = generateTimestamps();
  
    return (
      <div className="loglist">
        {list.map((item, index) => (
          <div key={index} className="item">
            <span><strong>[{timestamps[index]}]</strong> {item}</span>
          </div>
        ))}
      </div>
    );
  };
const Dashboard = () => {
    return (
        <div className="dashboard">
            <div className="row one">
                <ProgressGauge title = "Units Syndicating"/>
                <ProgressGauge title = "Units Being Held" negative/>
                <ProgressGauge title = "Units w/o Photos" negative/>
                <ProgressGauge title = "Units w/o Data" negative/>
                <ProgressGauge title = "Units w/o Price" negative/>
            </div>
            <div className="row two">
                <NewVsUsedGraph/>
                <UnitTypeData/>
            </div>
            <div className="row three">
                <LogList list={[
                    'Ian M. updated the price on unit #123123',
                    'Richard D. deleted unit #120321',
                    'Richard D. deleted unit #220145',
                    'Jordan D. uploaded images to unit #221221',
                    'Ian M. scheduled a new feed',
                    'Richard D. bulk updated 200 units',
                ]} />
                <LogList list={[
                    'STEALTH received a feed from ProResponse',
                    'STEALTH sent a feed to RVUSA',
                    'STEALTH sent a feed to RV Trader',
                    'STEALTH received a feed from IDS',
                    'STEALTH sent a feed to Dealer Website',
                    'STEALTH automatically priced 30 units',
                    'STEALTH warned dealer of low inventory',
                ]} />
            </div>
        </div>
    )
}

export default Dashboard;