import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/authcontext.js';
import DataTableWithHeader from '../../../components/elements/dataTableWithHeader/datatablewithheader.js';
import Button from '../../../components/elements/button/button.js';
import { Inventory } from '../../../api';
import './inventorykey.css';
import Modal from '../../../components/elements/modal/modal.js';
import LabeledDropdown from '../../../components/elements/labeledDropdown/labeleddropdown.js';
import LabeledModifier from '../../../components/elements/labeledModifier/labeledmodifier.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import { faBinary, faDollarSign } from '@fortawesome/pro-regular-svg-icons';

const InventoryKey = ({environment}) => {
    const { inventory_key } = useParams();

    //authentication
    const auth = useContext(AuthContext);
    const inventory = new Inventory(auth,environment);
    const navigate = useNavigate();

    //bulk update
    const [bulkUpdateModalOpen,setBulkUpdateModalOpen] = useState(false);
    const [selectedBulkUpdateFields,setSelectedBulkUpdateFields] = useState([]);
    const [bulkUpdateFieldSelectorOpen,setBulkUpdateFieldSelectorOpen] = useState(false);
    const [bulkUpdateRows,setBulkUpdateRows] = useState([]);

    const [tableColumns,setTableColumns] = useState([
        { header: 'Name', accessor: 'name'},
    ]);

    const [tableData,setTableData] = useState([]);

    const actions = [
        { label: 'Bulk Update', func: (rows) => {
            setBulkUpdateRows(rows);
            setSelectedBulkUpdateFields([]);
            setBulkUpdateModalOpen(true);
        }, min: 1 },
        { label: 'Modify Unit', func: (rows) => {

        }, min: 1, max: 1 },
        { label: 'Delete Unit(s)', func: (rows) => {

        }, min: 1, max: 1 },
    ];

    const getInventory = async () => {
        setTableData([]);
        const data = await inventory.get(inventory_key);
        const inventoryAttributes = data.inventory ?? [];
        const fields = inventoryAttributes['fields'] ? JSON.parse(inventoryAttributes['fields']) : [];
        const units = data.units ?? [];
    
        const yearFieldIndex = fields.findIndex(field => field.type === 'year');
        const makeFieldIndex = fields.findIndex(field => field.type === 'make');
        const modelFieldIndex = fields.findIndex(field => field.type === 'model');
        const trimFieldIndex = fields.findIndex(field => field.type === 'trim');
    
        const filteredFields = fields.filter(field => !['year', 'make', 'model', 'trim'].includes(field.type));
    
        const combinedHeaderFields = [
            yearFieldIndex !== -1 ? fields[yearFieldIndex].field : undefined,
            makeFieldIndex !== -1 ? fields[makeFieldIndex].field : undefined,
            modelFieldIndex !== -1 ? fields[modelFieldIndex].field : undefined,
            trimFieldIndex !== -1 ? fields[trimFieldIndex].field : undefined
        ].filter(Boolean).join(" | ");

        const mappedFields = [{
            header: combinedHeaderFields,
            accessor: 0,
            type: 'yearMakeModelTrim'
        }];
    
        let accessorCounter = 1;
        filteredFields.forEach((field) => {
            //if (field.type !== "string" && field.type !== "integer") {
                mappedFields.push({ 
                    header: field.field, 
                    accessor: accessorCounter,
                    type: field.type
                });
                accessorCounter++;
            //}
        });

        const mappedUnits = units.map((unit, index) => {
            const unitData = unit.data ?? '{}';
            const unitDataParsed = JSON.parse(unitData);
            let unitValues = Object.values(unitDataParsed);
        
            const yearValue = unitValues[yearFieldIndex];
            const makeValue = unitValues[makeFieldIndex];
            const modelValue = unitValues[modelFieldIndex];
            const trimValue = unitValues[trimFieldIndex];

            const unit_key = unit['unit_key'];

            const ymmt = [yearValue, makeValue, modelValue, trimValue].join(' ');
            const yearMakeModelTrim = ({
                                        display: <a 
                                            onClick={()=>{navigate(`/unit/${unit_key}`)}} 
                                            style={{letterSpacing:'-0.5px',fontSize:'1.1em',fontWeight:'bold',textAlign:'center'}}
                                        >{ymmt}</a>,
                                        value: ymmt
                                    })
        
            const indicesToRemove = [yearFieldIndex, makeFieldIndex, modelFieldIndex, trimFieldIndex].sort((a, b) => a - b);
            for (let i = indicesToRemove.length - 1; i >= 0; i--) {
                unitValues.splice(indicesToRemove[i], 1);
            }
        
            // Calculate the number of empty strings to add
            const unitValuesLengthDifference = fields.length - (unitValues.length);
        
            // Add the empty strings
            for (let i = 0; i < unitValuesLengthDifference; i++) {
                unitValues.push('');
            }
        
            unitValues = unitValues.map((value, index) => {
                let fieldType = (mappedFields[index + 1]??[]).type; // Ensure this index aligns correctly with your mappedFields
                switch (fieldType) {
                    case 'msrp':
                    case 'selling_price':
                    case 'invoice_amount':
                        return ({
                            display: <LabeledInput isPrice icon={faDollarSign} value={value} onChange={()=>{}}/>,
                            value: value,
                        });
                    case 'type':
                        return ({
                            display: <LabeledInput value={value} onChange={()=>{}}/>,
                            value: value,
                        });
                    case 'condition_state':
                        return ({
                                    display: <LabeledDropdown
                                        placeholder="Select.."
                                        options={[
                                            {value:'new',label:'new',key:'new'},
                                            {value:'used',label:'used',key:'used'}
                                        ]}
                                        value={value}
                                        onChange={()=>{}} 
                                    />,
                                    value: value,
                                });
                    default:
                        return ({
                            display: <LabeledInput value={value} onChange={()=>{}}/>,
                            value: value,
                        });
                }
            });            
        
            unitValues.unshift(yearMakeModelTrim);
        
            return unitValues;
        });
    
        setTableColumns(mappedFields);
        setTableData(mappedUnits);
    }
    
    
    

    useEffect(()=>{
        getInventory();
    },[]);

    return (
        <div className={bulkUpdateModalOpen ? 'inventorykey overlay-active' : 'inventorykey'}>
            <Modal
                isOpen = {bulkUpdateModalOpen}
                onClose = {()=>{setBulkUpdateModalOpen(false)}}
                size = {{
                    width: '300px',
                }}
                title={`Bulk Update (${bulkUpdateRows.length})`}
            >
                <div className="bulkUpdate">
                    <LabeledDropdown
                        title="Field(s)" 
                        placeholder="Select a field(s)..."
                        options={tableColumns
                            .filter(column => column.type !== 'yearMakeModelTrim')
                            .map((column)=>{
                            return {
                                value:column.accessor,
                                label:column.header,
                                key:column.accessor
                            }
                        })}
                        value={selectedBulkUpdateFields}
                        icon={faBinary}
                        onChange={setSelectedBulkUpdateFields} 
                        stateUpdater={setBulkUpdateFieldSelectorOpen}
                        multiSelect
                    />
                    {!bulkUpdateFieldSelectorOpen&&
                        selectedBulkUpdateFields.map((index)=>{
                            let field = tableColumns[index];
                            //header,accessor,type
                            switch (field.type) {
                                case 'msrp':
                                case 'selling_price':
                                case 'invoice_amount':
                                    return (<LabeledModifier
                                        key = {index}
                                        title = {field.header}
                                        type = "price"
                                    />);
                                case 'condition_state':
                                    return (<LabeledModifier
                                        key = {index}
                                        title = {field.header}
                                        type = "dropdown"
                                        dropdownOptions={[
                                            {value:'new',label:'new',key:'new'},
                                            {value:'used',label:'used',key:'used'}
                                        ]}
                                    />);
                                case 'integer':
                                    return (<LabeledModifier
                                        key = {index}
                                        title = {field.header}
                                        type = "integer"
                                    />);
                                default:
                                    return (<LabeledModifier
                                        key = {index}
                                        title = {field.header}
                                        type = "text"
                                    />);
                            }
                        })
                    }
                    <div className="actions">
                        <button id="confirm" onClick={()=>{setBulkUpdateModalOpen(false)}}>Confirm</button>
                        <button id="cancel" onClick={()=>{setBulkUpdateModalOpen(false)}}>Cancel</button>
                    </div>
                </div>
            </Modal>
            <DataTableWithHeader 
                title="Units"
                tableDataCount={tableData.length}
                actions={actions}
                tableColumns={tableColumns}
                tableData={tableData}
                onSync={getInventory}
            >
                <Button text="Create Unit" variation="primary" onClick={() => {  }} />
            </DataTableWithHeader>
        </div>
    )
}

export default InventoryKey;
