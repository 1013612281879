import axios from 'axios';

export default class Group {
    constructor(auth,environment) {
        this.auth = auth;
        this.environment = environment;
    }

    async post(name) {
        try {
            const response = await axios.post(`${this.environment.domain}${this.environment.api.endpoint}/group`,
            {
                'name':name,
            },
            {
                headers: {
                    'AccessToken': this.auth.tokens.AccessToken,
                }
            });

            if (!response.data || !response.data.status) {
                console.log('Internal error. Please try again later.');
                return;
            }

            let status = response.data.status;
            let message = response.data.message;

            if (status == 'success') {
                let key = response.data.key;
                console.log(response.data.key);
                return key;
            } else {
                throw message;
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            throw error;
        }   
    }

    async delete(group_key) {
        try {
            await axios.delete(`${this.environment.domain}${this.environment.api.endpoint}/group/${group_key}`, {
                headers: {
                    'AccessToken': this.auth.tokens.AccessToken,
                }
            });
                    
            return true;
        } catch (error) {
            console.error("Error fetching data: ", error);
            throw error;
        }   
    }
}