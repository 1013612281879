import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/pro-regular-svg-icons';
import './actionsdropdown.css';

const ActionsDropdown = ({ title, icon, actions, disabled, selectedRows }) => {
    const uniqueId = "actions-dropdown-" + uuidv4();
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDropdownToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleActionClick = (actionFunc) => {
        if (actionFunc) {
            actionFunc(selectedRows);
        }
        setIsOpen(false);
    }

    return (
        <div className="ActionsDropdown">
            <label htmlFor={uniqueId}>
                {title}
            </label>
            <div 
                className={`dropdownContainer ${isOpen ? 'focused' : ''} ${disabled ? 'disabled' : ''}`} 
                onClick={handleDropdownToggle}
                ref={dropdownRef}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                <span className="actionsLabel">Actions</span>
                <FontAwesomeIcon 
                    className={`caretIcon ${isOpen ? 'rotated' : ''}`} 
                    icon={faCaretLeft}
                />

                {isOpen && (
                    <div className="optionsContainer">
                        {actions.map((action, index) => {
                            const min = action.min || 0;
                            const max = action.max || Infinity;

                            const isEnabled = selectedRows.length >= min && selectedRows.length <= max;

                            return (
                                <div 
                                    key={index} 
                                    className={`option ${!isEnabled ? 'disabled' : ''}`} 
                                    onClick={isEnabled ? () => handleActionClick(action.func) : null}
                                >
                                    {action.label}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ActionsDropdown;
