import React, { useState, useEffect, useContext } from 'react';
import Section from '../../../components/elements/section/section.js';
import Button from '../../../components/elements/button/button.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import LabeledDropdown from '../../../components/elements/labeledDropdown/labeleddropdown.js';
import { faBucket, faArrowProgress, faClock, faFile, faTag, faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import { Groups } from '../../../api';
import AuthContext from '../../../context/authcontext.js';

import './feedsetup.css';

const FeedSetup = ({environment}) => {
    const auth = useContext(AuthContext);
    const groups = new Groups(auth,environment);

    const [name, setName] = useState('');
    const [groupsList,setGroupsList] = useState([]);
    const [groupOptions,setGroupOptions] = useState([]);

    const [method, setMethod] = useState('');
    const [frequency, setFrequency] = useState('');

    //s3
    const [s3bucket, setS3Bucket] = useState('');
    const [s3filePath, setS3FilePath] = useState('');
    
    const getAllGroupOptions = async () => {
        setGroupOptions([]);
        setGroupsList([]);

        const data = await groups.get();
        const mappedGroups = data.map((group, index) => {
            const { group_key:value = '', name:label = '' } = group;
    
            return {
                id: index,
                label,
                value,
            };
        });
    
        setGroupOptions(mappedGroups);      
    }

    useEffect(()=>{
        getAllGroupOptions();
    },[]);

    return (
        <div className="feedsetup">
            <div className="setupContainer">
                <header>
                    <h1>Feed Setup <span className="clickable info">Info</span></h1>
                    <h2>A feed organizes incoming data into structured inventory fields within Stealth Inventory. This feature allows you to map raw data to useable inventory fields effectively. Once the feed is successfully configured, you'll have a streamlined process for integrating new data into the system.</h2>
                </header>

                {/* NAME AND TAGS SECTION TO-DO: ADD INSTANCE ORIENTATION */}
                <>
                    <Section
                        title = "Name and Tags"
                    >
                        <LabeledInput
                            title="Name" 
                            placeholder="e.g. ProResponse Feed" 
                            icon={faTag}
                            value={name}
                            onChange={(event) => setName(event.target.value)} 
                        />
                        <LabeledDropdown
                            title="Group" 
                            placeholder="Select group..."
                            options={groupOptions}
                            value={groupsList}
                            icon={faLayerGroup}
                            onChange={setGroupsList} 
                            refreshData={getAllGroupOptions}
                        />
                    </Section>
                </>

                {/* NAME AND TAGS SECTION */}
                <>
                    <Section
                        title = "Retrieval Method & Frequency"
                    >
                        <LabeledDropdown
                            title="Retrieval Method"
                            placeholder="Select a retrieval method..."
                            options={[
                                {key: 'api', label: 'API', value: 'api'},
                                {key: 'ftp', label: 'FTP', value: 'ftp'},
                                {key: 'sftp', label: 'SFTP', value: 'sftp'},
                                {key: 's3', label: 'AWS S3', value: 's3'},
                            ]}
                            value={method}
                            icon={faArrowProgress}
                            onChange={setMethod} 
                        />
                        <LabeledDropdown
                            title="Frequency"
                            placeholder="Select a frequency..."
                            options={[
                                {key: '5min', label: 'Every 5 Minutes', value: '5min'},
                                {key: '10min', label: 'Every 10 Minutes', value: '10min'},
                                {key: '15min', label: 'Every 15 Minutes', value: '15min'},
                                {key: '30min', label: 'Every 30 Minutes', value: '30min'},
                                {key: '1hr', label: 'Every Hour', value: '1hr'},
                                {key: 'daily', label: 'Daily', value: 'daily'},
                            ]}
                            value={frequency}
                            icon={faClock}
                            onChange={setFrequency} 
                        />
                    </Section>
                </>

                {/* AWS CONFIGURATION SECTION */}
                {
                    (method=="s3"&&frequency!='')&&
                    <>
                        <Section
                            title = "AWS Configuration"
                        >
                            <LabeledDropdown
                                title="S3 Bucket"
                                placeholder="Select a bucket..."
                                options={[]}
                                value={s3bucket}
                                icon={faBucket}
                                onChange={setS3Bucket} 
                            />
                            <LabeledInput
                                title="File Path" 
                                placeholder="e.g. /units/inventory.csv" 
                                icon={faFile}
                                value={s3filePath}
                                onChange={(event)=>{setS3FilePath(event.target.value)}} 
                            />
                        </Section>
                    </>
                }

            </div>
            <div className="summaryContainer">
                <Section title="Summary">
                    <Button text="Setup Feed" onClick={()=>{
                    }} />
                </Section>
            </div>
        </div>
    )
}


export default FeedSetup;