import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import LabeledInput from '../labeledInput/labeledinput.js';
import LabeledDropdown from '../labeledDropdown/labeleddropdown.js';
import './labeledmodifier.css';

const LabeledModifier = ({ title, disabled, type='text', dropdownOptions }) => {
    //types: integer, price, dropdown, text
    const uniqueId = "labeled-modifier-" + uuidv4();
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState('');
    const [selectedOperation, setSelectedOperation] = useState(null);

    const add = { label: '+', value: 'add', key: 'add' };
    const subtract = { label: '−', value: 'subtract', key: 'subtract' };
    const multiply = { label: '×', value: 'multiply', key: 'multiply' };
    const set = { label: '=', value: 'set', key: 'set' };

    const dynamicModifierOptions = [set];

    switch (type) {
        case 'integer':
            dynamicModifierOptions.push(add);
            dynamicModifierOptions.push(subtract);
            dynamicModifierOptions.push(multiply);
            break;
        case 'price':
            dynamicModifierOptions.push(add);
            dynamicModifierOptions.push(subtract);
            dynamicModifierOptions.push(multiply);
            break;
    }

    const placeholder = (dynamicModifierOptions.length === 1) ? dynamicModifierOptions[0].label : '?';

    useEffect(() => {
        const selectedOption = dynamicModifierOptions.find(opt => opt.value === selectedOperation);
        if (selectedOption) {
            setSelectedLabel(selectedOption.label);
        } else {
            setSelectedLabel(placeholder);
        }
    }, [selectedOperation, dynamicModifierOptions, placeholder]);

    const handleDropdownToggle = () => {
        if (!disabled && dynamicModifierOptions.length > 1) {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOperation(option.value);
        setSelectedLabel(option.label);
        setIsOpen(false);
    };

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="labeledModifier">
            <label htmlFor={uniqueId}>
                {title}
            </label>
            <div className="container">
                {/* Operations Dropdown */}
                <div 
                    className={`selectable operationContainer ${isOpen ? 'focused' : ''} ${disabled ? 'disabled' : ''}`} 
                    onClick={handleDropdownToggle}
                    ref={dropdownRef}
                >
                    <span className="selectedValue">
                        {selectedLabel}
                    </span>

                    {(dynamicModifierOptions.length>1)&&<FontAwesomeIcon 
                        className={`caretIcon ${isOpen ? 'rotated' : ''}`} 
                        icon={faCaretLeft}
                    />}

                    {isOpen && (
                        <div className="optionsContainer">
                            {dynamicModifierOptions.filter(option => option.value !== selectedOperation).map((option, index) => (
                                <div 
                                    key={index} 
                                    className="option"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOptionClick(option);
                                    }}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {/* Modifier Value - types: text, integer, price, dropdown */}
                {type==="text"&&<LabeledInput/>}
                {type==="integer"&&<LabeledInput/>}
                {type==="price"&&<LabeledInput icon={faDollarSign} isPrice/>}
                {type==="dropdown"&&<LabeledDropdown
                    placeholder="Select.."
                    options={dropdownOptions}
                    value={''}
                    onChange={()=>{}} 
                />}
            </div>
        </div>
    );
}

export default LabeledModifier;
