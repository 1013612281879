import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/authcontext.js';
import DataTableWithHeader from '../../../components/elements/dataTableWithHeader/datatablewithheader.js';
import Button from '../../../components/elements/button/button.js';
import { Groups, Group } from '../../../api';
import './allgroups.css';

const AllGroups = ({environment}) => {
    //authentication
    const auth = useContext(AuthContext);
    const groups = new Groups(auth,environment);
    const group = new Group(auth,environment);
    const navigate = useNavigate();

    const tableColumns = [
        { header: 'Name', accessor: 'name'},
        { header: 'Group Key', accessor: 'group_key'},
    ];

    const [tableData,setTableData] = useState([]);

    const actions = [
        { label: 'Modify Group', func: (rows) => {
            navigate(`/group/${rows[0]['group_key']}`)
        }, min: 1, max: 1 },
        { label: 'Delete Group', func: (rows) => {
            group.delete(rows[0]['group_key']).then(getAllGroups);
        }, min: 1, max: 1 },
    ];

    const getAllGroups = async () => {
        setTableData([]);
        const data = await groups.get();
        const mappedGroups = data.map((group, index) => {
            const { group_key = '', name = '' } = group;
            return {
                id: index,
                group_key,
                name,
            };
        });
        setTableData(mappedGroups);
    }

    useEffect(()=>{
        getAllGroups();
    },[]);

    return (
        <div className="allgroups">
            <DataTableWithHeader 
                title="All Groups"
                tableDataCount={tableData.length}
                actions={actions}
                tableColumns={tableColumns}
                tableData={tableData}
                onSync={getAllGroups}
            >
                <Button text="Create Group" variation="primary" onClick={() => { navigate('/command_center/create_group') }} />
            </DataTableWithHeader>
        </div>
    )
}

export default AllGroups;
