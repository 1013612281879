import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

import './labeledinput.css';

const formatPrice = (value) => {
    return parseFloat(value).toLocaleString('en-US', {
        //maybe I will need to add digits back.. it may be important for pricing
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    });
};

const LabeledInput = ({ title, placeholder, icon, onChange, onDelete, value, disabled, isPrice, variation }) => {
    variation = variation ? variation : 'default';
    const uniqueId = "labeled-input-" + uuidv4();
    const inputRef = useRef(null);
    const [inputFocused, setInputFocused] = useState(false);
    const [displayValue, setDisplayValue] = useState(value);

    useEffect(() => {
        if (!inputFocused && isPrice) {
            setDisplayValue(formatPrice(value));
        } else {
            setDisplayValue(value);
        }
    }, [value, inputFocused, isPrice]);

    const handleFocus = () => {
        setInputFocused(true);
    };

    const handleBlur = () => {
        setInputFocused(false);
        if (isPrice) {
            setDisplayValue(formatPrice(value));
        }
    };

    const handleChange = (e) => {
        onChange(e);
    };

    const handleSearchClick = () => {
        inputRef.current.focus();
    };

    return (
        <div className={`labeledInput variation-${variation}`}>
            {title && <label htmlFor={uniqueId}>{title}</label>}
            <div onClick={handleSearchClick} className={`typeable inputContainer ${inputFocused ? 'focused' : ''} ${disabled ? 'disabled' : ''}`}>
                {icon && <FontAwesomeIcon icon={icon} />}
                <input
                    ref={inputRef}
                    id={uniqueId}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={placeholder}
                    value={inputFocused || !isPrice ? value : displayValue}
                    disabled={disabled}
                />
                {onDelete && <FontAwesomeIcon onClick={onDelete} className="clickable" icon={faTrash} />}
            </div>
        </div>
    );
};

export default LabeledInput;
