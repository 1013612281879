import axios from 'axios';


export default class Users {
    constructor(auth,environment) {
        this.auth = auth;
        this.environment = environment;
    }

    async get() {
        try {
            const response = await axios.get(`${this.environment.domain}${this.environment.api.endpoint}/users`, {
                headers: {
                    'AccessToken': this.auth.tokens.AccessToken,
                }
            });
            
            if (!response || !response.data || !response.data.users) { 
                console.error("Unexpected response format");
                return;
            }
        
            const dataArray = Object.values(response.data.users);
        
            return dataArray;
        } catch (error) {
            console.error("Error fetching data: ", error);
            throw error;
        }   
    }
}
