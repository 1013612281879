import React, { useState, useEffect } from 'react';
import LabeledInput from '../labeledInput/labeledinput.js';
import LabeledCheckbox from '../labeledCheckbox/labeledCheckbox.js';

import './hoursrangeinput.css';

const HoursRangeInput = ({label,returnTo}) => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [isClosed, setIsClosed] = useState(false);

    useEffect(()=>{
        if (returnTo) {
            returnTo({
                startTime:startTime,
                endTime:endTime,
                isClosed:isClosed
            })
        }
    },[startTime,endTime,isClosed])

    return (
        <div className="hoursRangeInput">
            {label&&<h4>{label}</h4>}
            <div className="rangeInput">
                <LabeledInput 
                    title="Start Time" 
                    placeholder="e.g. 08:00 AM" 
                    onChange={(event) => setStartTime(event.target.value)} 
                    disabled={isClosed}
                    value={startTime}
                />
                <LabeledInput 
                    title="End Time" 
                    placeholder="e.g. 05:00 PM" 
                    onChange={(event) => setEndTime(event.target.value)} 
                    disabled={isClosed}
                    value={endTime}
                />
                <LabeledCheckbox title="Closed" onChange={setIsClosed}/>
            </div>
        </div>
    )
}

export default HoursRangeInput;
