import './createinventory.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { faLayerGroup, faTag, faMotorcycle, faRv, faTruckMonster, faSailboat, faCarSide, faTractor, faForklift, faTrailer, faTruck, faWarehouse, faBinary } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Section from '../../../components/elements/section/section.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import LabeledDropdown from '../../../components/elements/labeledDropdown/labeleddropdown.js';
import Button from '../../../components/elements/button/button.js';

import AuthContext from '../../../context/authcontext.js';

import { Groups, Inventory } from '../../../api';

import defaultFields from './defaultfields.json';


const NameAndTags = ({returnTo,environment}) => {
    const [name,setName] = useState('');
    const [group,setGroup] = useState('');
    const [inventoryType,setInventoryType] = useState('');
    const [groupOptions,setGroupOptions] = useState([]);
    const auth = useContext(AuthContext);
    const groups = new Groups(auth,environment);

    const getAllGroupOptions = async () => {
        setGroupOptions([]);
        setGroup('');
        const data = await groups.get();

        
        const mappedGroups = data.map((group, index) => {
            const { group_key:value = '', name:label = '' } = group;
    
            return {
                id: index,
                label,
                value,
            };
        });
        
        setGroupOptions(mappedGroups);    
    }

    useEffect(()=>{
        getAllGroupOptions();
    },[]);

    useEffect(()=>{
        returnTo({
            name:name,
            group_key:group,
            inventoryType: inventoryType
        });
    },[name,group, inventoryType]);

    return (
        <Section
            title = "Name and Tags"
        >
            <LabeledInput
                title="Name" 
                placeholder="e.g. RVs Listed For Sale" 
                icon={faTag}
                onChange={(event) => setName(event.target.value)} 
            />
            <LabeledDropdown
                title="Group" 
                placeholder="Select a group..."
                options={groupOptions}
                value={group}
                icon={faLayerGroup}
                onChange={setGroup} 
                refreshData={getAllGroupOptions}
            />
            <LabeledDropdown
                title="Inventory Type" 
                placeholder="Select an inventory type..."
                options={[
                    {id: 1,value:'motorcycle',label:(<><FontAwesomeIcon icon={faMotorcycle} /> Motorcycles</>)},
                    {id: 2,value:'rv',label:(<><FontAwesomeIcon icon={faRv} /> Recreational Vehicles</>)},
                    {id: 3,value:'utv',label:(<><FontAwesomeIcon icon={faTruckMonster} /> UTVs</>)},
                    {id: 4,value:'boat',label:(<><FontAwesomeIcon icon={faSailboat} /> Boats</>)},
                    {id: 5,value:'passenger-vehicle',label:(<><FontAwesomeIcon icon={faCarSide} /> Passenger Vehicles</>)},
                    {id: 6,value:'commercial-vehicle',label:(<><FontAwesomeIcon icon={faTruck} /> Commercial Vehicles</>)},
                    {id: 7,value:'farm-equipment',label:(<><FontAwesomeIcon icon={faTractor} /> Farm Equipment</>)},
                    {id: 8,value:'industrial-equipment',label:(<><FontAwesomeIcon icon={faForklift} /> Industrial Equipment</>)},
                    {id: 9,value:'trailer',label:(<><FontAwesomeIcon icon={faTrailer} /> Trailers</>)},
                ]}
                value={inventoryType}
                icon={faWarehouse}
                onChange={setInventoryType} 
            />
        </Section>
    )
}

const Fields = ({returnTo,fields}) => {
    const [types,setTypes] = useState([]);

    useEffect(() => {
        const allTypes = defaultFields.flatMap(category => 
            category.fields.map(field => field.type)
        );
    
        const uniqueTypes = Array.from(new Set(allTypes));
    
        const typeOptions = uniqueTypes.map((type, index) => ({
            value: type,
            label: type.charAt(0).toUpperCase() + type.slice(1).replace(/_/g, ' '),
            key: index
        }));
    
        setTypes(typeOptions);
    
    }, [defaultFields]);

    return (
        <>
            {
                (fields.length > 0) && <Section
                title = "Fields"
                >
                    <div style={{display:'grid',gridTemplateColumns:'1fr 1fr',gap:'10px'}}>
                        {
                            fields.map((field)=>{
                                return (
                                    <LabeledDropdown
                                        title={field.field.charAt(0).toUpperCase() + field.field.slice(1).replace(/_/g, ' ')} 
                                        placeholder="Select a data type.."
                                        options={types}
                                        icon={faBinary}
                                        value={field.type}
                                        key={field.field}
                                        onChange={(type)=>{
                                            returnTo(fields => {
                                                return fields.map(option => {
                                                    if (option.field == field.field) {
                                                        return { ...option, type: type };
                                                    }
                                                    return option;
                                                });
                                            })
                                        }} 
                                    />
                                )
                            })
                        }
                    </div>
                </Section>
            }
        </>
    )
}

const CreateInventory = ({environment}) => {
    const [nameAndTags,setNameAndTags] = useState([]);
    const [fields,setFields] = useState([]);
    const navigate = useNavigate();

    //authentication
    const auth = useContext(AuthContext);
    const inventory = new Inventory(auth, environment);

    const handleInventoryCreation = async () => {
        const inventory_key = await inventory.post(nameAndTags.name, nameAndTags.group_key, nameAndTags.inventoryType, JSON.stringify(fields));
        navigate(`/inventory/${inventory_key}`);
    }

    useEffect(()=>{
        if (nameAndTags.inventoryType) {
            const category = defaultFields.find(cat => cat.category === nameAndTags.inventoryType);
            setFields(category ? category.fields : []);
        }
    },[nameAndTags]);

    return (
        <div className="createinventory">
            <div className="setupContainer">
                <header>
                    <h1>Create an Inventory <span className="clickable info">Info</span></h1>
                    <h2>TO-DO: Description here</h2>
                </header>
                <NameAndTags returnTo={setNameAndTags} environment={environment}/>
                <Fields returnTo={setFields} fields={fields} />
            </div>
            <div className="summaryContainer">
                <Section title="Summary">
                    <Button text="Launch Inventory" onClick={()=>{
                        handleInventoryCreation();
                    }} />
                </Section>
            </div>
        </div>
    )
}
//407 St Tammany St, Madisonville, LA 70447
export default CreateInventory;