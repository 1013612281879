import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import Table from '../../../components/elements/table/table.js';
import Button from '../../../components/elements/button/button.js';
import ActionsDropdown from '../../../components/elements/actionsDropdown/actionsdropdown.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import RowsPerPageDropdown from '../../../components/elements/rowsPerPageDropdown/rowsperpagedropdown.js';

import './datatablewithheader.css';

const DataTableWithHeader = ({
  title,
  tableDataCount,
  actions,
  tableColumns,
  tableData,
  onSync,
  children
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState('');

  // Calculate the number of pages
  const pageCount = Math.ceil(tableDataCount / rowsPerPage);
  
  // Function to handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < pageCount) {
      setCurrentPage(newPage);
    }
  };

  const isArrayWithObjects = Array.isArray(tableData) && tableData.every(row => typeof row === 'object' && row !== null);

  const canUseSome = isArrayWithObjects && tableData.length > 0 && tableData.every(row => 
    Array.isArray(row) && row.some(cell => cell && typeof cell === 'object' && cell.hasOwnProperty('value'))
  );

  let filteredTableData = tableData;

  if (canUseSome) {
    filteredTableData = tableData.filter(row => 
      row.some(cell => cell.value.includes(search))
    );
  }
  
  const currentTableData = filteredTableData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
  
  return (
    <div className="dataTableWithHeader">
      <header>
        <div className="top">
          <h1>{`${title} (${tableDataCount})`} <span className="clickable info">Info</span></h1>
          <div className="actionbar">
            <LabeledInput placeholder={"Search.."} variation="primary" value={search} onChange={(event)=>{setSearch(event.target.value)}}/>
            <Button text={<FontAwesomeIcon icon={faSyncAlt} />} variation="primary" onClick={onSync} />
            <RowsPerPageDropdown rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
            <Button text={<FontAwesomeIcon icon={faChevronLeft} />} variation="primary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
            <Button text={<FontAwesomeIcon icon={faChevronRight} />} variation="primary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount - 1} />
            <ActionsDropdown actions={actions} selectedRows={selectedRows} />
            {children}
          </div>
        </div>
      </header>
      <Table columns={tableColumns} data={currentTableData} setSelectedRows={setSelectedRows} />
    </div>
  );
}

export default DataTableWithHeader;
