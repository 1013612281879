import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';

import './authenticator.css';
import Logo from '../../assets/stealth-inventory-logo.webp';
import warp10logo2 from '../../assets/warp10-logo-2.webp';

const MovingBackground = ({children}) => {
    const [lines, setLines] = useState([]);

    // Colors to choose from
    const colors = [
        "#193972",
        "#142f5e",
        "#55c3e3",
        "#419bb6",
    ];

    useEffect(() => {
        // Create some random lines
        const numLines = 10;
        const newLines = [];
        for (let i = 0; i < numLines; i++) {
            const top = Math.random() * 100;
            const animationDuration = 5 + Math.random() * 10;
            const backgroundColor = colors[Math.floor(Math.random() * colors.length)]; // Randomly pick a color from the list
            newLines.push({ top, animationDuration, backgroundColor });
        }
        setLines(newLines);
    }, []);

    return (
        <div className="moving-bg-container">
            {lines.map((line, idx) => (
                <div 
                    key={idx} 
                    className="floating-line" 
                    style={{ 
                        top: `${line.top}vh`,
                        animationDuration: `${line.animationDuration}s`,
                        backgroundColor: line.backgroundColor  // Set the random background color
                    }} 
                />
            ))}
            {children}
        </div>
    );
}

const CredentialInput = ({icon, placeholder, setValue, type, autocomplete, name}) => {
    const inputRef = useRef(null);
    const [inputFocused, setInputFocused] = useState(false);

    useEffect(() => {
        if (autocomplete) {
            inputRef.current.value = '';  // Clear the input value on the initial render
        }
    }, [autocomplete]);

    const handleSearchClick = () => {
        inputRef.current.focus();
    }

    const handleFocus = () => {
        setInputFocused(true);
        if (autocomplete && !inputFocused) {
            inputRef.current.value = '';  // Ensure the input is clear on focus
        }
    }

    return (
        <div onClick={handleSearchClick} className={`input typeable ${name ? name : ''}${inputFocused ? 'focused' : ''}`}>
            <FontAwesomeIcon icon={icon} />
            <input
                ref={inputRef}
                onFocus={handleFocus}
                onBlur={() => setInputFocused(false)}
                placeholder={placeholder}
                onChange={(event) => {
                    setValue(event.target.value);
                }}
                name={name}
                type={type}
                id={name}
                {...(autocomplete ? { autoComplete: "none" } : {})}
            />
        </div>
    );
}


const LoginScreen = ({environment, setTokens}) => {
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [newPasswordOne,setNewPasswordOne] = useState('');
    const [newPasswordTwo,setNewPasswordTwo] = useState('');
    const [error,setError] = useState(null);
    const [forcePasswordReset,setForcePasswordReset] = useState(false);

    useEffect(()=>{
        if (forcePasswordReset) {
            handleSubmit();
            setForcePasswordReset(false);
        }
    },[password])

    const handleSubmit = () => {
        if (!username || username==='') {
            setError('Please fill out the username field.');
            return;
        }

        if (!password || password==='') {
            setError('Please fill out the password field.');
            return;
        }

        axios.post(environment.domain+environment.api.endpoint+'/authentication/authenticate-user', {
            username: username,
            password: password,
        })
        .then(response => {
            if (!response.data || !response.data.status) {
                setError('Internal error. Please try again later.');
                return;
            }

            let status = response.data.status;
            let message = response.data.message;

            if (status==="success") {
                let tokens = response.data.tokens;

                if (!tokens) {
                    setError("Something went wrong..");
                    return;
                }

                setUsername('');
                setPassword('');
                setTokens(tokens);
            } else if (status==="force_change_password") {
                setError("Please set a secure password.");
                setForcePasswordReset(true);
            } else {
                setError('Incorrect credentials given.');
                return;
            }
        })
        .catch(error => {
            setError('An unknown error has occured. Please try again.');
            console.log(error);
            return;
        });
    }

    const handlePasswordResetSubmit = () => {
        if (!username || username==='') {
            setError('Please fill out the username field.');
            return;
        }

        if (!password || password==='') {
            setError('Please fill out the password field.');
            return;
        }

        if (!newPasswordOne || newPasswordOne==='') {
            setError('Please fill out the password field.');
            return;
        }

        if (!newPasswordTwo || newPasswordTwo==='') {
            setError('Please fill out the password confirmation field.');
            return;
        }

        if (newPasswordOne != newPasswordTwo) {
            setError('The provided passwords do not match.');
            return;
        }

        axios.post(environment.domain+environment.api.endpoint+'/authentication/change-password', {
            username: username,
            password: password,
            newPassword: newPasswordOne,
        })
        .then(response => {
            if (!response.data || !response.data.status) {
                setError('Internal error. Please try again later.');
                return;
            }

            let status = response.data.status;
            let message = response.data.message;

            if (status == 'success') {
                setPassword(newPasswordOne);
            } else {
                if (message == "InvalidPasswordException") {
                    setError('The provided password does not meet the given standards.');
                    return;
                }
                setError('An unknown error has occured. Please try again.');
                return;
            }
        })
        .catch(error => {
            setError('An unknown error has occured. Please try again.');
            return;
        });
    }

    return (
        <div className="authenticator">
            <div className="login">
                <img className="logo" src={Logo} />
                <h1 className="title">Welcome Back!</h1>
                <div className="credentials">
                    {
                        forcePasswordReset ? (
                            <>
                                <span className="error" style={{display:(error ? 'block' : 'none')}}>{error ? error : ''}</span>
                                <CredentialInput setValue={setNewPasswordOne} icon={faLock} placeholder="password" type="password" name="password-reset-1" autocomplete="off"/>
                                <CredentialInput setValue={setNewPasswordTwo} icon={faLock} placeholder="confirm password" type="password" name="password-reset-2" autocomplete="off"/>
                                <button onClick={handlePasswordResetSubmit} className="clickable submit">Confirm</button>
                            </>
                        ) : (
                            <>
                                <span className="error" style={{display:(error ? 'block' : 'none')}}>{error ? error : ''}</span>
                                <CredentialInput setValue={setUsername} icon={faUser} placeholder="example@email.com" type="email" name="username"/>
                                <CredentialInput setValue={setPassword} icon={faLock} placeholder="password" type="password" name="password"/>
                                <button onClick={handleSubmit} className="clickable submit">Sign In</button>
                                <button className="clickable help">Forgot password?</button>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}


const Authenticator = ({children,environment,setTokens,isAuthenticated}) => {
    if (isAuthenticated) {
        return <>{children}</>
    } else {
        return (
            <MovingBackground>
                <LoginScreen environment={environment} setTokens={setTokens}/>
                <span className="accelerated-by">Accelerated by <img alt="" src={warp10logo2}/></span>
            </MovingBackground>
        )
    }
}

export default Authenticator;