import './button.css';

const Button = ({text, onClick, variation}) => {
    variation = variation ? variation : 'default';

    return (
        <button onClick={onClick} className={`clickable variation-${variation}`}>
            <span>{text}</span>
        </button>
    );
}

export default Button;