import React, { useRef, useState, useEffect } from 'react'; 
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

import './labeledimageupload.css';

const LabeledImageUpload = ({ title, onChange, onDelete, disabled, imageUrl }) => {
    const uniqueId = "labeled-image-upload-" + uuidv4();
    const inputRef = useRef(null);
    const [inputFocused, setInputFocused] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            }
            reader.readAsDataURL(file);
        }

        if (onChange) {
            onChange(e);
        }
    };

    useEffect(() => {
        if (imageUrl) {
            fetch(imageUrl)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImageSrc(reader.result);
    
                        // Trigger onChange with the fetched image data
                        if (onChange) {
                            onChange({ target: { files: [new File([blob], 'fetchedImage.png', { type: blob.type })] } });
                        }
                    }
                    reader.readAsDataURL(blob);
                })
                .catch(err => console.error("Error fetching image:", err));
        }
    }, [imageUrl, onChange]);    

    return (
        <div className="LabeledImageUpload">
            <label htmlFor={uniqueId}>
                {title}
            </label>
            <div className={`uploadContainer ${inputFocused ? 'focused' : ''} ${disabled ? 'disabled' : ''}`}>
                <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    id={uniqueId}
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                    onChange={handleImageChange}
                    disabled={disabled}
                />
                {imageSrc && <img src={imageSrc} alt="Uploaded Preview" />}
                {onDelete && <FontAwesomeIcon onClick={onDelete} className="clickable" icon={faTrash} />}
            </div>
        </div>
    );
}

export default LabeledImageUpload;