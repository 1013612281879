import './data_catalog_import.css';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { faTag, faIndustryWindows, faCalendarDays, faRv, faLink, faBinary } from '@fortawesome/pro-regular-svg-icons';
import cheerio from 'cheerio';

import { DataCatalog } from '../../../api';

import Section from '../../../components/elements/section/section.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import LabeledDropdown from '../../../components/elements/labeledDropdown/labeleddropdown.js';
import LabeledImageUpload from '../../../components/elements/labeledImageUpload/labeledimageupload.js';
import Button from '../../../components/elements/button/button.js';

import AuthContext from '../../../context/authcontext.js';

const DataCatalogImport = ({environment}) => {
    //debounce
    const [uploading,setUploading] = useState(false);

    //scrape url
    const [url, setUrl] = useState('');

    //name and tags
    const [year,setYear] = useState('');
    const [manufacturer,setManufacturer] = useState('');
    const [model,setModel] = useState('');
    const [trim,setTrim] = useState('');
    const [class_,setClass_] = useState('');

    //name and tags
    const [floorplanUrl,setFloorplanUrl] = useState(null);
    const [stockPhotoUrl,setStockPhotoUrl] = useState(null);
    const [stockPhoto,setStockPhoto] = useState(null);
    const [floorplan,setFloorplan] = useState(null);

    //attributes
    const [attributes,setAttributes] = useState([
        {key: 'air-conditioning-prewiring', label: 'Air Conditioning Prewiring', value: ''},
        {key: 'air-conditioning-type', label: 'Air Conditioning Type', value: ''},
        {key: 'awning-length', label: 'Awning Length', value: ''},
        {key: 'back-up-camera', label: 'Back Up Camera', value: ''},
        {key: 'basic-warranty-months', label: 'Basic Warranty Months', value: ''},
        {key: 'bathroom-flooring-type', label: 'Bathroom Flooring Type', value: ''},
        {key: 'bathroom-location', label: 'Bathroom Location', value: ''},
        {key: 'bathroom-medicine-cabinet-location', label: 'Bathroom Medicine Cabinet Location', value: ''},
        {key: 'bathroom-mirror-location', label: 'Bathroom Mirror Location', value: ''},
        {key: 'bathroom-sink-location', label: 'Bathroom Sink Location', value: ''},
        {key: 'bathroom-vent-/-fan-system-location', label: 'Bathroom Vent / Fan System Location', value: ''},
        {key: 'battery-converter-amps', label: 'Battery Converter Amps', value: ''},
        {key: 'battery-power-converter', label: 'Battery Power Converter', value: ''},
        {key: 'body-material', label: 'Body Material', value: ''},
        {key: 'bunkhouse', label: 'Bunkhouse', value: ''},
        {key: 'cable-prewiring', label: 'Cable Prewiring', value: ''},
        {key: 'convection', label: 'Convection', value: ''},
        {key: 'currency', label: 'Currency', value: ''},
        {key: 'door-type', label: 'Door Type', value: ''},
        {key: 'dry-weight', label: 'Dry Weight', value: ''},
        {key: 'front-brake-type', label: 'Front Brake Type', value: ''},
        {key: 'full-size-master-bedroom-closet', label: 'Full Size Master Bedroom Closet', value: ''},
        {key: 'gvwr', label: 'GVWR', value: ''},
        {key: 'heat-prewiring', label: 'Heat Prewiring', value: ''},
        {key: 'heater', label: 'Heater', value: ''},
        {key: 'heater-type', label: 'Heater Type', value: ''},
        {key: 'height', label: 'Height', value: ''},
        {key: 'hitch-weight', label: 'Hitch Weight', value: ''},
        {key: 'interior-height', label: 'Interior Height', value: ''},
        {key: 'interior-wood-finish', label: 'Interior Wood Finish', value: ''},
        {key: 'kitchen-/-living-area-flooring-type', label: 'Kitchen / Living Area Flooring Type', value: ''},
        {key: 'kitchen-location', label: 'Kitchen Location', value: ''},
        {key: 'kitchen-table-configuration', label: 'Kitchen Table Configuration', value: ''},
        {key: 'layout', label: 'Layout', value: ''},
        {key: 'length', label: 'Length', value: ''},
        {key: 'leveling-jack-type', label: 'Leveling Jack Type', value: ''},
        {key: 'living-area-location', label: 'Living Area Location', value: ''},
        {key: 'master-bedroom-door-style', label: 'Master Bedroom Door Style', value: ''},
        {key: 'master-bedroom-flooring-type', label: 'Master Bedroom Flooring Type', value: ''},
        {key: 'master-bedroom-location', label: 'Master Bedroom Location', value: ''},
        {key: 'master-bedroom-mirror-doors', label: 'Master Bedroom Mirror Doors', value: ''},
        {key: 'master-bedroom-shades-/-curtains', label: 'Master Bedroom Shades / Curtains', value: ''},
        {key: 'max-sleeping-count', label: 'Max Sleeping Count', value: ''},
        {key: 'metallic', label: 'Metallic', value: ''},
        {key: 'msrp', label: 'Msrp', value: ''},
        {key: 'msrp-+-destination', label: 'Msrp + Destination', value: ''},
        {key: 'number-of-awnings', label: 'Number Of Awnings', value: ''},
        {key: 'number-of-axles', label: 'Number Of Axles', value: ''},
        {key: 'number-of-bathrooms', label: 'Number Of Bathrooms', value: ''},
        {key: 'number-of-black-water-holding-tanks', label: 'Number Of Black Water Holding Tanks', value: ''},
        {key: 'number-of-bunk-beds', label: 'Number Of Bunk Beds', value: ''},
        {key: 'number-of-convertible-/-sofa-beds', label: 'Number Of Convertible / Sofa Beds', value: ''},
        {key: 'number-of-discs', label: 'Number Of Discs', value: ''},
        {key: 'number-of-doors', label: 'Number Of Doors', value: ''},
        {key: 'number-of-double-beds', label: 'Number Of Double Beds', value: ''},
        {key: 'number-of-fresh-water-holding-tanks', label: 'Number Of Fresh Water Holding Tanks', value: ''},
        {key: 'number-of-full-size-beds', label: 'Number Of Full Size Beds', value: ''},
        {key: 'number-of-gray-water-holding-tanks', label: 'Number Of Gray Water Holding Tanks', value: ''},
        {key: 'number-of-king-size-beds', label: 'Number Of King Size Beds', value: ''},
        {key: 'number-of-oven-burners', label: 'Number Of Oven Burners', value: ''},
        {key: 'number-of-propane-tanks', label: 'Number Of Propane Tanks', value: ''},
        {key: 'number-of-queen-size-beds', label: 'Number Of Queen Size Beds', value: ''},
        {key: 'number-of-radios', label: 'Number Of Radios', value: ''},
        {key: 'number-of-recliner-/-rockers', label: 'Number Of Recliner / Rockers', value: ''},
        {key: 'number-of-slideouts', label: 'Number Of Slideouts', value: ''},
        {key: 'number-of-sofas', label: 'Number Of Sofas', value: ''},
        {key: 'overhead-fan', label: 'Overhead Fan', value: ''},
        {key: 'paint-swatch-file-name', label: 'Paint Swatch File Name', value: ''},
        {key: 'payload-capacity', label: 'Payload Capacity', value: ''},
        {key: 'phone-prewiring', label: 'Phone Prewiring', value: ''},
        {key: 'power-retractable-awning', label: 'Power Retractable Awning', value: ''},
        {key: 'power-retractable-slideout', label: 'Power Retractable Slideout', value: ''},
        {key: 'primary-color', label: 'Primary Color', value: ''},
        {key: 'rear-brake-type', label: 'Rear Brake Type', value: ''},
        {key: 'rear-tire-full-spec', label: 'Rear Tire Full Spec', value: ''},
        {key: 'reclining-sofa', label: 'Reclining Sofa', value: ''},
        {key: 'refrigerator-power-mode', label: 'Refrigerator Power Mode', value: ''},
        {key: 'refrigerator-size', label: 'Refrigerator Size', value: ''},
        {key: 'roof-warranty-years', label: 'Roof Warranty Years', value: ''},
        {key: 'satellite', label: 'Satellite', value: ''},
        {key: 'satellite-prewiring', label: 'Satellite Prewiring', value: ''},
        {key: 'screened-room', label: 'Screened Room', value: ''},
        {key: 'shower-location', label: 'Shower Location', value: ''},
        {key: 'sidewall-construction', label: 'Sidewall Construction', value: ''},
        {key: 'sliding-glass-door', label: 'Sliding Glass Door', value: ''},
        {key: 'sofa-material', label: 'Sofa Material', value: ''},
        {key: 'solar', label: 'Solar', value: ''},
        {key: 'spare-tire-location', label: 'Spare Tire Location', value: ''},
        {key: 'speaker-locations', label: 'Speaker Locations', value: ''},
        {key: 'structure-warranty-months', label: 'Structure Warranty Months', value: ''},
        {key: 'tankless-water-heater', label: 'Tankless Water Heater', value: ''},
        {key: 'toilet-location', label: 'Toilet Location', value: ''},
        {key: 'toilet-type', label: 'Toilet Type', value: ''},
        {key: 'total-black-water-tank-capacity', label: 'Total Black Water Tank Capacity', value: ''},
        {key: 'total-fresh-water-tank-capacity', label: 'Total Fresh Water Tank Capacity', value: ''},
        {key: 'total-gray-water-tank-capacity', label: 'Total Gray Water Tank Capacity', value: ''},
        {key: 'total-propane-tank-capacity', label: 'Total Propane Tank Capacity', value: ''},
        {key: 'tv-antenna-prewiring', label: 'Tv Antenna Prewiring', value: ''},
        {key: 'wallpaper', label: 'Wallpaper', value: ''},
        {key: 'washer-/-dryer-prewiring', label: 'Washer / Dryer Prewiring', value: ''},
        {key: 'water-heater-pump-power-mode', label: 'Water Heater Pump Power Mode', value: ''},
        {key: 'water-heater-tank-bypass', label: 'Water Heater Tank Bypass', value: ''},
        {key: 'water-heater-tank-capacity', label: 'Water Heater Tank Capacity', value: ''},
        {key: 'wheels-composition', label: 'Wheels Composition', value: ''},
        {key: 'width', label: 'Width', value: ''}        
    ]);

    //scraper
    const scrapeURL = async ()=>{
        if (url.includes("rvusa")) {
            const response = await fetch(url);
            const responseText = await response.text();
            const $ = cheerio.load(responseText);
            
            const title = $('h1').html().trim().split('Specs for ')[1];

            const [yearMake, modelTrimClassRaw] = title.split(' - ');

            // Split yearMake by space to get year and make
            const [yearScraped, ...makeParts] = yearMake.split(' ');
            const makeScraped = makeParts.join(' ');

            // Parsing modelTrimClassRaw for model, trim, and class
            const [modelScraped, trimClassRaw] = modelTrimClassRaw.split('<br>');
            const trimMatch = trimClassRaw.match(/Floorplan: (.+?) \((.+?)\)/);

            if (!trimMatch) {
                throw new Error('Unexpected format');
            }

            console.log('here');

            const trimScraped = trimMatch[1].trim();
            const classScraped = trimMatch[2].trim();

            console.log(yearScraped.trim(), makeScraped.trim(), modelScraped.trim(), trimScraped.trim(), classScraped.trim());
            setYear(parseInt(yearScraped.trim()));
            setManufacturer(makeScraped.trim());
            setModel(modelScraped.trim());
            setTrim(trimScraped.trim());
            setClass_(classScraped.toLowerCase().replace(/ /g, "-"));

            //stock photo, floorplan, brochure
            var hrefs = [];

            $('img.img-fluid.my-auto').each(function() {
                console.log('found');
                hrefs.push($(this).attr('data-src'));
            });

            console.log('here');
            console.log(hrefs);

            setStockPhotoUrl(hrefs[0]);
            setFloorplanUrl(hrefs[1]);

            //attributes
            let attributePairs = [];

            // $('.s-row').each(function() {
            //     var label = $(this).find('.s-label').text().trim();
            //     let key = label.toLowerCase().replace(/ /g, "-").replace(/[()]/g, '');
            //     var value = $(this).find('.s-value').text().trim().split('*')[0];
            
            //     attributePairs.push({ key: key, label: label, value: value });
            // });

            $('.specsGuideTabs .p-3 .p-2 .row[style="border-bottom: 1px solid gainsboro;"]').each(function() {
                var label = $(this).find('div:eq(0)').text().trim();
                let key = label.toLowerCase().replace(/ /g, "-").replace(/[()]/g, '');
                var value = $(this).find('div:eq(1)').text().trim().split('*')[0];
            
                attributePairs.push({ key: key, label: label, value: value });
            });

            setAttributes(attributePairs);

            console.log("finito'");
        }
    }

    const navigate = useNavigate();

    const years = Array.from({ length: (new Date().getFullYear()) - 1899 + 1 }, (_, i) => {
        const year = 1900 + i;
        return { label: year.toString(), value: year };
      }).reverse();

    //authentication
    const auth = useContext(AuthContext);
    const datacatalog = new DataCatalog(auth, environment);

    const importUnit = async () => {
        setUploading(true);

        const mappedAttributes = attributes.reduce((acc, curr) => {
            acc[curr.key] = curr.value;
            return acc;
        }, {});
    
        const formData = new FormData();
        formData.append('floorplan', floorplan);
        formData.append('stockPhoto', stockPhoto);
    
        // Append other data
        formData.append('AccessToken', auth.tokens.AccessToken);
        formData.append('IdToken', auth.tokens.IdToken);
        formData.append('year', year.toString());
        formData.append('manufacturer', manufacturer);
        formData.append('model', model);
        formData.append('trim', trim);
        formData.append('class', class_); 
        formData.append('attributes', JSON.stringify(mappedAttributes));
    
        const rv_key = await datacatalog.post(formData);

        if (rv_key) {
            navigate(`/catalog-rv/${rv_key}`);
        } else {
            setUploading(false);
        }   
    }
    

    return (
        <div className="datacatalogimport">
            <div className="setupContainer">
                <header>
                    <h1>Data Catalog Import <span className="clickable info">Info</span></h1>
                    <h2>Stealth Inventory empowers you to import units into the data catalog, streamlining the process of data management and organization within the system. Once units are successfully imported, they are immediately available for reference and utilization in the catalog.</h2>
                </header>


                {/* SCRAPER */}
                <>
                    <Section
                        title = "Scraper"
                    >
                        <span className="warning">To enable the functionality of this web scraper, you must have the 'Allow CORS: Access-Control-Allow-Origin' Chrome Extension installed and enabled during its usage. You can find the extension <a target="_blank" href="https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf">here</a>. Please be aware that attempting to use this scraper without the extension will result in errors.<br/><br/>It is essential to understand and adhere to the terms of use of any website you intend to scrape data from. This operation runs directly in your web browser and uses your IP address to access the target site. You are solely responsible for ensuring your compliance with all applicable laws and regulations while using this tool.</span>
                        <LabeledInput
                            title="URL" 
                            placeholder="e.g. www.coasttechnology.com" 
                            icon={faLink}
                            value={url}
                            onChange={(event) => setUrl(event.target.value)} 
                        />
                        <Button text="Scrape URL" onClick={scrapeURL} />
                    </Section>
                </>


                {/* NAME AND TAGS SECTION */}
                <>
                    <Section
                        title = "Name and Tags"
                    >
                        <LabeledDropdown
                            title="Year" 
                            placeholder="Select a year..."
                            options={years}
                            value={year}
                            icon={faCalendarDays}
                            onChange={setYear} 
                        />
                        <LabeledInput
                            title="Manufacturer" 
                            placeholder="e.g. Jayco" 
                            icon={faIndustryWindows}
                            value={manufacturer}
                            onChange={(event) => setManufacturer(event.target.value)} 
                        />
                        <LabeledInput
                            title="Model" 
                            placeholder="e.g. Jay Flight" 
                            icon={faTag}
                            value={model}
                            onChange={(event) => setModel(event.target.value)} 
                        />
                        <LabeledInput
                            title="Trim" 
                            placeholder="e.g. 236TH" 
                            icon={faTag}
                            value={trim}
                            onChange={(event) => setTrim(event.target.value)} 
                        />
                        <LabeledDropdown
                            title="Class" 
                            placeholder="Select a class..."
                            options={[
                                {label: 'Travel Trailer', value: 'travel-trailer'},
                                {label: 'Fifth Wheel', value: 'fifth-wheel'},
                                {label: 'Class A', value: 'class-a'},
                                {label: 'Truck Camper', value: 'truck-camper'},
                                {label: 'Toy Hauler', value: 'toy-hauler'},
                                {label: 'Expandable Trailer', value: 'expandable-trailer'},
                                {label: 'Destination Trailer', value: 'destination-trailer'},
                                {label: 'Popup', value: 'popup'},
                                {label: 'Class C', value: 'class-c'},
                                {label: 'Class B', value: 'class-b'},
                            ]}
                            value={class_}
                            icon={faRv}
                            onChange={setClass_} 
                        />
                    </Section>
                </>


                {/* Images / Others */}
                <>
                    <Section
                        title = "Images / Others"
                    >
                        <LabeledImageUpload
                            title="Stock Photo" 
                            value={stockPhoto}
                            onChange={(event) => setStockPhoto(event.target.files[0])} 
                            imageUrl={stockPhotoUrl}
                        />
                        <LabeledImageUpload
                            title="Floorplan" 
                            value={floorplan}
                            onChange={(event) => setFloorplan(event.target.files[0])} 
                            imageUrl={floorplanUrl}
                        />
                    </Section>
                </>


                {/* Attributes */}
                <>
                    <Section
                        title = "Attributes"
                    >
                        <div style={{display:'grid',gridTemplateColumns:'1fr 1fr',gap:'10px'}}>
                            {
                            attributes.map((attribute)=>{
                                return (
                                    <LabeledInput
                                        title={attribute.label} 
                                        icon={faBinary}
                                        value={attribute.value}
                                        key={attribute.key}
                                        onChange={(event)=>{
                                            const newValue = event.target.value;
                                            setAttributes(prevState => {
                                                const index = prevState.findIndex(a => a.key === attribute.key);
                                                const newState = [...prevState];
                                                newState[index].value = newValue;
                                                return newState;
                                            });
                                        }} 
                                    />
                                )
                            })  
                            }
                        </div>
                    </Section>
                </>
            </div>
            <div className="summaryContainer">
                <Section title="Summary">
                    {!uploading && <Button text="Import" onClick={()=>{
                        if (!uploading) {
                            importUnit();
                        }
                    }} />}
                </Section>
            </div>
        </div>
    )
}

export default DataCatalogImport;