
import React from 'react';
import LabeledDropdown from '../../../components/elements/labeledDropdown/labeleddropdown.js';

import './rowsperpagedropdown.css';

const RowsPerPageDropdown = ({ rowsPerPage, setRowsPerPage }) => {
  // Rows per page options
  const rowsPerPageOptions = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 75, label: '75' },
    { value: 100, label: '100' },
    { value: 125, label: '125' },
    { value: 150, label: '150' },
  ];

  // Function to handle rows per page change
  const handleRowsPerPageChange = (newValue) => {
    setRowsPerPage(newValue);
  };

  return (
    <LabeledDropdown
      placeholder="Select rows"
      options={rowsPerPageOptions}
      value={rowsPerPage}
      onChange={handleRowsPerPageChange}
      extraClass ="rowsPerPageDropdown"
    />
  );
};

export default RowsPerPageDropdown;
