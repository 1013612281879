import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users } from '../../../api';
import AuthContext from '../../../context/authcontext.js';
import DataTableWithHeader from '../../../components/elements/dataTableWithHeader/datatablewithheader.js';
import Button from '../../../components/elements/button/button.js';
import './allusers.css';

const AllUsers = ({environment}) => {
    //authentication
    const auth = useContext(AuthContext);
    const users = new Users(auth,environment);
    const navigate = useNavigate();

    const tableColumns = [
        { header: 'Name', accessor: 'name'},
        { header: 'Email', accessor: 'email'},
        { header: 'Role', accessor: 'role'},
        { header: 'User Key', accessor: 'user_key'},
    ]

    const [tableData,setTableData] = useState([]);

    const actions = [
        { label: 'Modify User', func: () => { } },
        { label: 'Delete User', func: () => { } },
        { label: 'Disconnect User', func: () => { } },
    ];

    const getAllUsers = async () => {
        setTableData([]);

        const data = await users.get();
        const mappedUsers = data.map((user, index) => {
            const { name = '', email = '', role = '', sub:user_key = '' } = user;
            return {
                id: index,
                name,
                email,
                role,
                user_key
            };
        });
    
        setTableData(mappedUsers);
    }

    useEffect(()=>{
        getAllUsers();
    },[]);

    return (
        <div className="allusers">
            <DataTableWithHeader 
                title="All Users"
                tableDataCount={tableData.length}
                actions={actions}
                tableColumns={tableColumns}
                tableData={tableData}
                onSync={getAllUsers}
            >
                <Button text="Create User" variation="primary" onClick={() => { navigate('/command_center/create_user') }} />
            </DataTableWithHeader>
        </div>
    )
}

export default AllUsers;