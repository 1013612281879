import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

//directories
import Dashboard from '../../directories/dashboard/dashboard.js';

import GroupInventories from '../../directories/inventory/group_inventories/groupinventories.js';
import InventoryKey from '../../directories/inventory/inventory_key/inventorykey.js';

import UnitKey from '../../directories/unit/unit_key/unitkey.js';

import CreateInventory from '../../directories/command_center/create_inventory/createinventory.js';
import CreateGroup from '../../directories/command_center/create_group/creategroup.js';
import AllGroups from '../../directories/command_center/all_groups/allgroups.js';
import CreateInstance from '../../directories/command_center/create_location/createlocation.js';
import AllInstances from '../../directories/command_center/all_locations/alllocations.js';
import AllUsers from '../../directories/command_center/all_users/allusers.js';
import CreateUser from '../../directories/command_center/create_user/createuser.js';
import FeedSetup from '../../directories/command_center/feed_setup/feedsetup.js';
import DataCatalogImport from '../../directories/command_center/data_catalog_import/data_catalog_import.js';

import './page.css';

const undoUrlSlug = (str) => {
    return str.toUpperCase().replace(/_/g, " ");
};

const Page = ({environment}) => {
    let location = useLocation();
    let segments = location.pathname.split('/').filter(Boolean);

    let directory = segments[0] ? segments[0] : 'DASHBOARD';
    let page = segments[1];

    return (
        <div className="page">
            <div className="navigation">
                <span>
                    STEALTH INVENTORY <FontAwesomeIcon icon={faArrowRight} /> {undoUrlSlug(directory.toUpperCase())} 
                    {page && (<> <FontAwesomeIcon icon={faArrowRight} /> {undoUrlSlug(page)}</>)}
                </span>
            </div>
            <div className="container">
                <Routes>
                    <Route path="/" element={<Dashboard environment={environment}/>} />

                    <Route path="/inventory/group_inventories" element={<GroupInventories environment={environment}/>} />
                    <Route path="/inventory/:inventory_key" element={<InventoryKey environment={environment}/>} />

                    <Route path="/unit/:unit_key" element={<UnitKey environment={environment}/>} />

                    <Route path="/command_center/create_group" element={<CreateGroup environment={environment}/>} />
                    <Route path="/command_center/create_inventory" element={<CreateInventory environment={environment}/>} />
                    <Route path="/command_center/all_groups" element={<AllGroups environment={environment}/>} />
                    <Route path="/command_center/create_location" element={<CreateInstance environment={environment}/>} />
                    <Route path="/command_center/all_locations" element={<AllInstances environment={environment}/>} />
                    <Route path="/command_center/create_user" element={<CreateUser environment={environment}/>} />
                    <Route path="/command_center/all_users" element={<AllUsers environment={environment}/>} />
                    <Route path="/command_center/feed_setup" element={<FeedSetup environment={environment}/>} />
                    <Route path="/command_center/data_catalog_import" element={<DataCatalogImport environment={environment}/>} />
                </Routes>
                <div className="spacer" style={{height:'50px'}}/>
            </div>
        </div>
    );
}

export default Page;