import './createuser.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { faUserSecret, faTag, faEnvelope, faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import { Groups, User } from '../../../api';

import Section from '../../../components/elements/section/section.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import LabeledDropdown from '../../../components/elements/labeledDropdown/labeleddropdown.js';
import Button from '../../../components/elements/button/button.js';

import AuthContext from '../../../context/authcontext.js';

const NameAndTags = ({returnTo,environment}) => {
    const [name,setName] = useState('');
    const [role,setRole] = useState('');
    const [email,setEmail] = useState('');
    const [groupsList,setGroupsList] = useState([]);
    const [groupOptions,setGroupOptions] = useState([]);
    const [roleOptions,setRoleOptions] = useState([]);
    const auth = useContext(AuthContext);
    const groups = new Groups(auth,environment);

    const getAllGroupOptions = async () => {
        setGroupOptions([]);
        setGroupsList([]);

        const data = await groups.get();
        const mappedGroups = data.map((group, index) => {
            const { group_key:value = '', name:label = '' } = group;
    
            return {
                id: index,
                label,
                value,
            };
        });
    
        setGroupOptions(mappedGroups);      
    }

    const getAvailableRoles = () => {
        const mappedRolesArray = auth.user.role_overlooks.map(str => ({
            label: str.charAt(0).toUpperCase() + str.slice(1), // Capitalize the first letter
            value: str
        }));
        setRoleOptions(mappedRolesArray);    
    }

    useEffect(()=>{
        getAvailableRoles();
        getAllGroupOptions();
    },[]);

    useEffect(()=>{
        returnTo({
            name:name,
            role:role,
            email:email,
            groupsList:groupsList,
        });
    },[name,role,email, groupsList]);

    return (
        <Section
            title = "Name and Tags"
        >
            <LabeledInput
                title="Name" 
                placeholder="e.g. Seymour Leads" 
                icon={faTag}
                onChange={(event) => setName(event.target.value)} 
            />
            <LabeledInput
                title="Email" 
                placeholder="e.g. seymour@coasttechnology.com" 
                icon={faEnvelope}
                onChange={(event) => setEmail(event.target.value)} 
            />
            <LabeledDropdown
                title="Role" 
                placeholder="Select a role..."
                options={roleOptions}
                value={role}
                icon={faUserSecret}
                onChange={setRole} 
            />
            <LabeledDropdown
                title="Group(s)" 
                placeholder="Select group(s)..."
                options={groupOptions}
                value={groupsList}
                icon={faLayerGroup}
                onChange={setGroupsList} 
                refreshData={getAllGroupOptions}
                multiSelect
            />
        </Section>
    )
}

const CreateUser = ({environment}) => {
    const [nameandtags,setNameAndTags] = useState(null);
    const navigate = useNavigate();

    //authentication
    const auth = useContext(AuthContext);
    const user = new User(auth,environment);

    const createAUser = async () => {
        const user_key = await user.post(nameandtags.name,nameandtags.email,nameandtags.role,nameandtags.groupsList.join(","));
        navigate(`/user/${user_key}`);
    }

    return (
        <div className="createuser">
            <div className="setupContainer">
                <header>
                    <h1>Create a User <span className="clickable info">Info</span></h1>
                    <h2>Stealth Inventory empowers you to create users, tailor-made profiles integral for managing access and roles within the system. Upon successful user creation, a file will be automatically generated and downloaded, containing the user's temporary credentials for initial access.</h2>
                </header>
                <NameAndTags returnTo={setNameAndTags} environment={environment}/>
            </div>
            <div className="summaryContainer">
                <Section title="Summary">
                    <Button text="Create User" onClick={()=>{
                        createAUser();
                    }} />
                </Section>
            </div>
        </div>
    )
}

export default CreateUser;