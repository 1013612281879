import React from 'react';
import ReactModal from 'react-modal';

import './modal.css';

class Modal extends React.Component {
    render() {
        const { title, isOpen, onClose, children, size = { width: 'auto', height: 'auto' } } = this.props;

        const customStyles = {
            content: {
                width: size.width,
                height: size.height,
                position: 'relative',
            },
            overlay: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
        };
        

        return (
            <ReactModal 
                isOpen={isOpen} 
                onRequestClose={onClose}
                style={customStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                className="custom-modal"
            >
                {title&&<h1 id="title">{title}</h1>}
                <button onClick={onClose} className="close-button">
                    &times;
                </button>
                {children}
            </ReactModal>
        );
    }
}

export default Modal;
