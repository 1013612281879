import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/authcontext.js';
import DataTableWithHeader from '../../../components/elements/dataTableWithHeader/datatablewithheader.js';
import Button from '../../../components/elements/button/button.js';
import { Locations } from '../../../api/index.js';
import './alllocations.css';

const AllLocations = ({environment}) => {
    const auth = useContext(AuthContext);
    const locations = new Locations(auth,environment);
    const navigate = useNavigate();

    const tableColumns = [
        { header: 'Name', accessor: 'name'},
        { header: 'Group', accessor: 'group_name'},
        { header: 'Website', accessor: 'website'},
        { header: 'City', accessor: 'city'},
        { header: 'State', accessor: 'state'},
        { header: 'Postal Code', accessor: 'postalCode'},
        { header: 'Location Key', accessor: 'location_key'},
        { header: 'Group Key', accessor: 'group_key'},
    ];

    const [tableData,setTableData] = useState([]);

    const actions = [
        { label: 'Modify Location', func: () => { } },
        { label: 'Delete Location', func: () => { } },
    ];

    const getAllLocations = async () => {
        setTableData([]);
        const data = await locations.get();
        const mappedLocations = data.map((location, index) => {
            const { 
                location_key = '', 
                group_key = '', 
                group_name = '',
                name = '',
                address = '',
                city = '',
                postalCode = '',
                state = '',
                website = ''
            } = location;

            return {
                id: index,
                location_key,
                group_key,
                group_name,
                name,
                address,
                city,
                state,
                postalCode,
                website
            };
        });
        setTableData(mappedLocations);
    }

    useEffect(()=>{
        getAllLocations();
    },[]);

    return (
        <div className="alllocations">
            <DataTableWithHeader 
                title="All Locations"
                tableDataCount={tableData.length}
                actions={actions}
                tableColumns={tableColumns}
                tableData={tableData}
                onSync={getAllLocations}
            >
                <Button text="Create Location" variation="primary" onClick={() => { navigate('/command_center/create_location') }} />
            </DataTableWithHeader>
        </div>
    )
}

export default AllLocations;
