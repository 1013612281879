import React, { useState, useEffect, useContext } from 'react';
import { faBinary, faCalendar, faDollarSign, faPercent } from '@fortawesome/pro-regular-svg-icons';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useParams, useNavigate } from 'react-router-dom';
import Section from '../../../components/elements/section/section.js';
import Button from '../../../components/elements/button/button.js';
import ButtonList from '../../../components/elements/buttonlist/buttonlist.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import OptionList from '../../../components/elements/optionlist/optionlist.js';

import './unitkey.css';
import '@splidejs/react-splide/css';

const images = [
    'https://content.homenetiol.com/2001243/2231693/0x0/15b43bb23b46472b98d76617c2b3f34e.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/833d025f720b44ca9386fefb5bfab2c9.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/9c2ef0e79a794938a1d7b79aa1607013.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/105cbcb0ae724463997268bf1821d00c.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/d213202c68a040509622c504efd5a1c3.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/c33ff31eabbd40d9bccf2f70668f6c0c.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/6d9c0be5438f4672bc5d4efccac0607a.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/46d0b828972544079dfaf254e425504f.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/eaf02f1637c044fc946216b898914d6d.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/d2fe00055ada4143a930534c6328384b.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/1fc799369edb4287a04acbafa55f851d.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/e00567604f504fb1a4fde551b3803c70.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/468d413852bf4484aa6b583a518461eb.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/5c55cf489c89470887800c70a1d01fce.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/125385502bb54b95958cf8c77ac848b4.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/8a66ec8134504ba0b1370727fef0c2ba.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/57be0f382cf2488e8126da749816f7a7.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/a2872f51be3a447b95d5ff9ee18aef75.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/9dfeaaf886034f82b32e85bc0cde1865.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/a1ac74262d1b4d1cab8d8fc577593468.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/f08b16c2a7074b5c9f50047df75af377.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/9079b645290f4e519e5f4a6cef2075b2.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/1b18ef5a34124ece9f1242877c326ea0.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/ebcd3dd55b0546d4bf9f72ae50f09fb8.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/eb090189d1bd4eb18597a0b46aa7723a.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/eefe44b0c0694ba8b397b1c1f840baa2.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/8a6ae79ad851454c821a0fda66a6cabf.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/cafb5d763e39492bb6f21ddfdc2a494b.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/46e9b6d81b4c4363952e0f5d4cdc0299.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/be8fcf1c05f6495eb91a1dedf4b6eb67.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/d76257484ee6414e87f9e6f791aeb630.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/154ce3fc619044648487e53545a0c2f6.jpg',
    'https://content.homenetiol.com/2001243/2231693/0x0/2ed2b93939714136b367d12d17568bc5.jpg'
];

const floorplan = "https://spec.dlrwebservice.com/sb-rv/floorplan/2024_Keystone_Alpine_3011CK.jpg";


const Floorplan = ({condition}) => {
    return (
        <>
            {(condition)&&
                <>
                    <div className="display">
                        <span>Floorplan</span>
                    </div>
                    <Splide aria-label="floorplan">
                        <SplideSlide>
                            <img src={floorplan} alt={`image`}/>
                        </SplideSlide>
                    </Splide>
                </>
            }
        </>
    )
}


const Walkthrough = ({condition}) => {
    return (
        <>
            {(condition)&&
                <>
                    <div className="display">
                        <span>Walkthrough</span>
                    </div>
                    <iframe data-v-32976bde="" data-v-1de15ca5="" src="https://my.matterport.com/show/?m=aunX77nNjXd" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" class="embed-html"></iframe>
                </>
            }
        </>
    )
}

const Video = ({ condition }) => {
    // Ensure you provide the YouTube video ID as a prop when using this component
  
    const videoSrc = `https://www.youtube.com/embed/QlTSG61bFW0?rel=0`;
  
    return (
      <>
        {condition && (
          <>
            <div className="display">
              <span>Video</span>
            </div>
            <iframe 
              title="Walkthrough Video" 
              width="100%" 
              height="auto" 
              src={videoSrc} 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </>
        )}
      </>
    );
  };

const Photos = ({condition}) => {
    return (
        <>
            {(condition)&&
                <>
                    <div className="display">
                        <span>2024 Keystone Alpine 3011CK</span>
                    </div>
                    <Splide aria-label="unit images">
                        {images.map((image,index)=>(
                            <SplideSlide key={index}>
                                <img src={image} alt={`image ${index}`}/>
                            </SplideSlide>
                        ))}
                    </Splide>
                </>
            }
        </>
    )
}

const formatDate = (date) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  
  const LogList = ({ list }) => {
    // Function to generate timestamps
    const generateTimestamps = () => {
      let currentTime = new Date();
      return list.map(() => {
        // Subtract a random amount of time between 1 and 5 minutes for the first timestamp
        const minutesToSubtract = 1 + Math.random() * 4;
        currentTime.setMinutes(currentTime.getMinutes() - minutesToSubtract);
  
        // For the subsequent timestamps, subtract additional random time between 1 and 20 minutes
        currentTime = new Date(currentTime.getTime() - (1 + Math.random() * 19) * 60000);
        return formatDate(currentTime);
      });
    };
  
    // Generate timestamps once for the entire list to maintain descending order
    const timestamps = generateTimestamps();
  
    return (
      <div className="loglist">
        {list.map((item, index) => (
          <div key={index} className="item">
            <span><strong>[{timestamps[index]}]</strong> {item}</span>
          </div>
        ))}
      </div>
    );
  };

const UnitKey = ({environment}) => {
    const { unit_key } = useParams();
    const [view, setView] = useState(0);

    return (
        <div className="unitkey">
            <div className="top">
                <div className="left">
                    <Section title="Unit Editor" frame>
                        <LabeledInput
                            title="Year" 
                            value="2024"
                            onChange={() => {}} 
                        />
                        <LabeledInput
                            title="Manufacturer" 
                            value="Keystone"
                            onChange={() => {}} 
                        />
                        <LabeledInput
                            title="Brand" 
                            value="Alpine"
                            onChange={() => {}} 
                        />
                        <LabeledInput
                            title="Model" 
                            value="3011CK"
                            onChange={() => {}} 
                        />
                        <LabeledInput
                            title="Class"
                            value="5th Wheel"
                            onChange={() => {}} 
                        />
                    </Section>
                    <Section title="Sections" className="info" frame headless>
                        <ButtonList 
                            vertical
                            buttons = {[
                                {
                                    text: "General",
                                    onClick: ()=>{setView(0)}
                                },
                                {
                                    text: "Pricing",
                                    onClick: ()=>{setView(1)}
                                },
                                {
                                    text: "Photo & Video",
                                    onClick: ()=>{setView(2)}
                                },
                                // {
                                //     text: "Tools",
                                //     onClick: ()=>{setView(3)}
                                // },
                                {
                                    text: "History",
                                    onClick: ()=>{setView(4)}
                                },
                            ]}
                        />
                    </Section>
                </div>
                <div className="middle">
                    <Photos condition={view===2}/>
                    {view===2&&<>
                        <Section frame title="Banners">
                            <OptionList 
                                options = {[
                                ]}
                            />
                        </Section>
                        <Section frame title="Photo Filters">
                            <OptionList 
                                options = {[
                                    'Increased Brightness',
                                    'Sharpened Image'
                                ]}
                            />
                        </Section>
                    </>}
                    {view===0&&<>
                        <Section frame title="Condition">
                            <LabeledInput
                                value="New"
                                onChange={() => {}} 
                            />
                        </Section>
                        <Section frame title="Floorplan">
                            <OptionList 
                                options = {[
                                    'Rear Living',
                                    'Front Bed',
                                    'Mid Kitchen',
                                    'Mid Bath',
                                ]}
                            />
                        </Section>
                        <Section frame title="Options">
                            <OptionList 
                                options = {[
                                    'Pearl Interior Decor',
                                    'Onan 550W Generator',
                                    'SolarFlex Discover 400i',
                                    '15K BTU Third A/C',
                                    'CSA 18 Cubic Foot Residential Refrigerator',
                                    'Blue Slate Full Body Paint',
                                    'White / Black Exterior Color',
                                    'Generator Prep',
                                    'Copper Mist Full Body Paint',
                                    'Frost Black Full Body Paint'
                                ]}
                            />
                        </Section>
                    </>}
                    {view===1&&<>
                        <Section frame title="General Pricing">
                            <LabeledInput
                                title="MSRP / List Price"
                                icon={faDollarSign}
                                value="132,209"
                                onChange={() => {}} 
                            />
                            <LabeledInput
                                title="Selling Price"
                                icon={faDollarSign}
                                value="79,991"
                                onChange={() => {}} 
                            />
                            <LabeledInput
                                title="Invoice Price"
                                icon={faDollarSign}
                                value="68,000"
                                onChange={() => {}} 
                            />
                        </Section>
                        <Section frame title="Financing">
                            <div style={{display: 'flex', gap: '10px'}}>
                                <LabeledInput
                                    title="APR"
                                    icon={faPercent}
                                    value="8.99"
                                    onChange={() => {}} 
                                />
                                <LabeledInput
                                    title="Down"
                                    icon={faPercent}
                                    value="20"
                                    onChange={() => {}} 
                                />
                                <LabeledInput
                                    title="Term"
                                    icon={faCalendar}
                                    value="240 Months"
                                    onChange={() => {}} 
                                />
                            </div>
                            <LabeledInput
                                title="Est. Monthly Payment"
                                icon={faDollarSign}
                                value="575"
                                onChange={() => {}} 
                            />
                        </Section>
                    </>}
                    {view===4&&<>
                        <LogList list={[
                            'Ian M. updated the MSRP',
                            'Richard D. deleted a photo',
                            'Richard D. changed the year',
                            'Jordan D. uploaded images',
                            'Ian M. reversed a change made by Jordan D.',
                            'Richard D. printed a brochure',
                        ]} />
                    </>}
                </div>
                <div className="right">
                    <Photos condition={[0,1].includes(view)}/>
                    <Floorplan condition={[0,1,2].includes(view)}/>
                    <Walkthrough condition={[0,1,2].includes(view)}/>
                    <Video condition={view===2}/>
                </div>
            </div>
            <div className="bottom">
                <div className="left">

                </div>
                <div className="middle">
                </div>
                <div className="right">

                </div>
            </div>
        </div>
    );
}

export default UnitKey;