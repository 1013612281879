import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faArrowsRotate, faSquare, faSquareCheck, faSpinner, faCheck } from '@fortawesome/pro-regular-svg-icons';
import './labeleddropdown.css';

const LabeledDropdown = ({ extraClass, title, placeholder, options, icon, onChange, value, disabled, refreshData, multiSelect, stateUpdater = (()=>{}) }) => {
    const uniqueId = "labeled-dropdown-" + uuidv4();
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState(Array.isArray(value) ? value.map(v => (options.find(opt => opt.value === v) || {}).label) : [placeholder]);

    useEffect(()=>{
        stateUpdater(isOpen);
    },[isOpen])

    useEffect(() => {
        setSelectedLabels([]);
    },[options]);

    useEffect(() => {
        if (multiSelect) {
            if (value.length === 0) {
                setSelectedLabels([]);
            } else {
                const labels = value.map(v => (options.find(opt => opt.value === v) || {}).label);
                setSelectedLabels(labels);
            }
        } else {
            const selectedOption = options.find(opt => opt.value === value);
            if (selectedOption) {
                setSelectedLabels([selectedOption.label]);
            } else {
                setSelectedLabels([]);
            }
        }
    }, [value, options, multiSelect, placeholder]);

    const handleDropdownToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionClick = (option) => {
        if (multiSelect) {
            const updatedValues = value.includes(option.value) ? value.filter(v => v !== option.value) : [...value, option.value];
            if (onChange) {
                onChange(updatedValues);
            }
        } else {
            if (onChange) {
                onChange(option.value);
            }
            setSelectedLabels([option.label]);
            setIsOpen(false);
        }
    };

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={`labeledDropdown ${extraClass}`}>
            <label htmlFor={uniqueId}>
                {title}
            </label>
            <div 
                className={`selectable dropdownContainer ${isOpen ? 'focused' : ''} ${disabled ? 'disabled' : ''}`} 
                onClick={handleDropdownToggle}
                ref={dropdownRef}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                <span className="selectedValue">
                    {selectedLabels.length === 0 
                        ? placeholder 
                        : selectedLabels.map((label, index) => (
                            <span key={index} className="individualLabel">
                                {label}{index < selectedLabels.length - 1 ? ', ' : ''}
                            </span>
                        ))
                    }
                </span>

                <FontAwesomeIcon 
                    className={`caretIcon ${isOpen ? 'rotated' : ''}`} 
                    icon={faCaretLeft}
                />
                {refreshData && (
                    <FontAwesomeIcon 
                        onClick={(e) => {
                            e.stopPropagation(); 
                            refreshData();
                        }} 
                        className="clickable" 
                        icon={faArrowsRotate} 
                    />
                )}

                {isOpen && (
                    <div className="optionsContainer">
                        {options.length === 0 ? (
                            <div className="loading-container">
                                <FontAwesomeIcon icon={faSpinner} spin />
                            </div>
                        ) : (
                            options.map((option, index) => (
                                <div 
                                    key={index} 
                                    className={`option ${multiSelect && value.includes(option.value) ? 'selected' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevents the event from bubbling up.
                                        handleOptionClick(option);
                                    }}
                                >
                                    {multiSelect && (value.includes(option.value) ? <FontAwesomeIcon className="clickable" icon={faSquareCheck} /> : <FontAwesomeIcon className="clickable" icon={faSquare} />)}
                                    {(!multiSelect && value===option.value) && <FontAwesomeIcon className="clickable" icon={faCheck} />}
                                    {option.label}
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default LabeledDropdown;
