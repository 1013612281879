import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { faHouse, faCity, faFlag, faMailbox, faTag, faChairOffice, faBrowser, faPhone, faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';

import Section from '../../../components/elements/section/section.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import LabeledDropdown from '../../../components/elements/labeledDropdown/labeleddropdown.js';
import HoursRangeInput from '../../../components/elements/hoursRangeInput/hoursrangeinput';
import Button from '../../../components/elements/button/button.js';
import AuthContext from '../../../context/authcontext.js';

import { Groups, Location } from '../../../api';

import './createlocation.css';

const NameAndTags = ({returnTo,environment}) => {
    const [name,setName] = useState('');
    const [group,setGroup] = useState('');
    const [groupOptions,setGroupOptions] = useState([]);
    const auth = useContext(AuthContext);
    const groups = new Groups(auth,environment);

    const getAllGroupOptions = async () => {
        setGroupOptions([]);
        setGroup('');
        const data = await groups.get();

        
        const mappedGroups = data.map((group, index) => {
            const { group_key:value = '', name:label = '' } = group;
    
            return {
                id: index,
                label,
                value,
            };
        });
        
        setGroupOptions(mappedGroups);    
    }

    useEffect(()=>{
        getAllGroupOptions();
    },[]);

    useEffect(()=>{
        returnTo({
            name:name,
            group_key:group
        });
    },[name,group]);

    return (
        <Section
            title = "Name and Tags"
        >
            <LabeledInput
                title="Name" 
                placeholder="e.g. Coast Technology" 
                icon={faTag}
                onChange={(event) => setName(event.target.value)} 
            />
            <LabeledDropdown
                title="Group" 
                placeholder="Select a group..."
                options={groupOptions}
                value={group}
                icon={faLayerGroup}
                onChange={setGroup} 
                refreshData={getAllGroupOptions}
            />
        </Section>
    )
}

const LocationDetails = ({returnTo}) => {
    const [address,setAddress] = useState('');
    const [city,setCity] = useState('');
    const [state,setState] = useState('');
    const [postalCode,setPostalCode] = useState('');
    const [website,setWebsite] = useState('');

    useEffect(()=>{
        returnTo({
            address:address,
            city:city,
            state:state,
            postalCode:postalCode,
            website:website
        });
    },[address,city,state,postalCode,website]);

    return (
        <Section
            title = "Location Details"
        >
            <LabeledInput 
                title="Address" 
                placeholder="e.g. 407 St Tammany St" 
                icon={faHouse}
                value={address}
                onChange={(event) => setAddress(event.target.value)} 
            />
            <LabeledInput 
                title="City" 
                placeholder="e.g. Madisonville" 
                icon={faCity}
                value={city}
                onChange={(event) => setCity(event.target.value)} 
            />
            <LabeledInput 
                title="State" 
                placeholder="e.g. LA" 
                icon={faFlag}
                value={state}
                onChange={(event) => setState(event.target.value)} 
            />
            <LabeledInput 
                title="Postal Code" 
                placeholder="e.g. 70447" 
                icon={faMailbox}
                value={postalCode}
                onChange={(event) => setPostalCode(event.target.value)} 
            />
            <LabeledInput 
                title="Website" 
                placeholder="e.g. www.coasttechnology.com" 
                icon={faBrowser}
                value={website}
                onChange={(event) => setWebsite(event.target.value)} 
            />
        </Section>
    )
}

const DepartmentDetails = ({key,title,returnTo}) => {
    const [phone,setPhone] = useState('');
    const [website,setWebsite] = useState('');
    const [sundayHours, setSundayHours] = useState('');
    const [mondayHours, setMondayHours] = useState('');
    const [tuesdayHours, setTuesdayHours] = useState('');
    const [wednesdayHours, setWednesdayHours] = useState('');
    const [thursdayHours, setThursdayHours] = useState('');
    const [fridayHours, setFridayHours] = useState('');
    const [saturdayHours, setSaturdayHours] = useState('');

    useEffect(() => {
        if (returnTo) {
            returnTo({
                phone: phone,
                website: website,
                hours: {
                    sunday: sundayHours,
                    monday: mondayHours,
                    tuesday: tuesdayHours,
                    wednesday: wednesdayHours,
                    thursday: thursdayHours,
                    friday: fridayHours,
                    saturday: saturdayHours
                }
            });
        }
    }, [phone, website, sundayHours, mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours, saturdayHours]);


    return (
        <Section key={key} title={title}>
            <LabeledInput 
                title="Phone" 
                placeholder="e.g. (985) 326-1081" 
                icon={faPhone}
                value={phone}
                onChange={(event) => setPhone(event.target.value)} 
            />
            <LabeledInput 
                title="Website" 
                placeholder="e.g. www.coasttechnology.com/sales"
                icon={faBrowser}
                value={website}
                onChange={(event) => setWebsite(event.target.value)} 
            />
            <HoursRangeInput label="Sunday" returnTo={setSundayHours} />
            <HoursRangeInput label="Monday" returnTo={setMondayHours} />
            <HoursRangeInput label="Tuesday" returnTo={setTuesdayHours} />
            <HoursRangeInput label="Wednesday" returnTo={setWednesdayHours} />
            <HoursRangeInput label="Thursday" returnTo={setThursdayHours} />
            <HoursRangeInput label="Friday" returnTo={setFridayHours} />
            <HoursRangeInput label="Saturday" returnTo={setSaturdayHours} />
        </Section>
    )
}

const Departments = ({returnTo}) => {
    const [departments, setDepartments] = useState([{ name: '', details: {}, id: uuidv4() }]);

    const updateDepartment = (index, name) => {
        const updatedDepartments = [...departments];
        updatedDepartments[index].name = name;
        setDepartments(updatedDepartments);
    }

    const updateDepartmentDetails = (index, details) => {
        const updatedDepartments = [...departments];
        updatedDepartments[index].details = details;
        setDepartments(updatedDepartments);
    };

    const addDepartment = () => {
        setDepartments(prevDepartments => [...prevDepartments, { name: '', details: {}, id: uuidv4() }]);
    };

    const deleteButtonClicked = (id) => {
        const updatedDepartments = departments.filter(dept => dept.id !== id);
        setDepartments(updatedDepartments);
    };

    useEffect(()=>{
        const filteredDepartments = departments.filter(dept => dept.name.trim() !== '');
        returnTo(filteredDepartments);
    },[departments]);

    return (
        <>
            <Section title="Departments">
                {departments.map((department, index) => (
                    <LabeledInput 
                        key={department.id}
                        title={`${department.name!==''?`${department.name} `:''}Department`} 
                        placeholder="e.g. Sales" 
                        icon={faChairOffice}
                        value={department.name}
                        onChange={(event) => updateDepartment(index, event.target.value)} 
                        onDelete={(index !== 0)||(departments.length>1) ? () => deleteButtonClicked(department.id) : undefined}
                    />
                ))}
                <Button text="Add Another Department" onClick={addDepartment} />
            </Section>

            {departments.filter(department => department.name.trim() !== '').map((department, index) => (
                <DepartmentDetails 
                    key={index} 
                    title={`${department.name} Department Details`}
                    returnTo={(details) => updateDepartmentDetails(index, details)}
                />
            ))}
        </>
    )
}


const CreateLocation = ({environment}) => {
    const [nameAndTags,setNameAndTags] = useState(null);
    const [locationDetails,setLocationDetails] = useState(null);
    const [departments,setDepartments] = useState(null);
    const navigate = useNavigate();

    //authentication
    const auth = useContext(AuthContext);
    const location = new Location(auth, environment);

    const handleLocationCreation = async () => {
        const location_key = await location.post(nameAndTags.name, nameAndTags.group_key, locationDetails.address, locationDetails.city, locationDetails.state, locationDetails.postalCode, locationDetails.website, JSON.stringify(departments));
        navigate(`/location/${location_key}`);
    }

    return (
        <div className="createlocation">
            <div className="setupContainer">
                <header>
                    <h1>Create a Location <span className="clickable info">Info</span></h1>
                    <h2>Stealth Inventory lets you establish databases, referred to as locations, designed to house dealership details and manage inventory feeds.</h2>
                </header>
                <NameAndTags returnTo={setNameAndTags} environment={environment}/>
                <LocationDetails returnTo={setLocationDetails}/>
                <Departments returnTo={setDepartments}/>
            </div>
            <div className="summaryContainer">
                <Section title="Summary">
                    <Button text="Launch Location" onClick={()=>{
                        handleLocationCreation();
                    }} />
                </Section>
            </div>
        </div>
    )
}
//407 St Tammany St, Madisonville, LA 70447
export default CreateLocation;