import axios from 'axios';

export default class Location {
    constructor(auth,environment) {
        this.auth = auth;
        this.environment = environment;
    }

    async post(name, group_key, address, city, state, postalCode, website, departments) {
        try {
            const response = await axios.post(`${this.environment.domain}${this.environment.api.endpoint}/location`,
            {
                'name': name,
                'group_key': group_key,
                'address': address,
                'city': city,
                'state': state,
                'postalCode': postalCode,
                'website': website,
                'departments': departments
            },
            {
                headers: {
                    'AccessToken': this.auth.tokens.AccessToken,
                }
            });

            if (!response.data || !response.data.status) {
                console.log('Internal error. Please try again later.');
                return;
            }

            let status = response.data.status;
            let message = response.data.message;

            if (status == 'success') {
                let key = response.data.key;
                console.log(response.data.key);
                return key;
            } else {
                throw message;
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            throw error;
        }   
    }
}