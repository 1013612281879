import './section.css';

const Section = ({className, children, title, frame, headless}) => {
    return (
        <section className={className+(frame?' frame':'')+(headless?' headless':'')}>
            {!headless&&<h3>{title} <span className="clickable info">{frame?'':'Info'}</span></h3>}
            <div className="content">
                {children}
            </div>
        </section>
    );
}

export default Section;