import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/authcontext.js';
import DataTableWithHeader from '../../../components/elements/dataTableWithHeader/datatablewithheader.js';
import Button from '../../../components/elements/button/button.js';
import { Inventories, Group } from '../../../api';
import './groupinventories.css';

const GroupInventories = ({environment}) => {
    //authentication
    const auth = useContext(AuthContext);
    const inventories = new Inventories(auth,environment);
    const group = new Group(auth,environment);
    const navigate = useNavigate();

    const tableColumns = [
        { header: 'Name', accessor: 'name'},
        { header: 'Type', accessor: 'type'},
        { header: 'Inventory Key', accessor: 'inventory_key'},
    ];

    const [tableData,setTableData] = useState([]);

    const actions = [
        { label: 'Modify Inventory', func: (rows) => {
            console.log(rows[0]);
            navigate(`/inventory/${rows[0]['inventory_key_value']}`)
        }, min: 1, max: 1 },
    ];

    const getInventories = async () => {
        setTableData([]);
        const data = await inventories.get();
        const mappedInventories = data.map((inventory, index) => {
            const { name = '', type = '', inventory_key = '' } = inventory;
            return {
                id: index,
                name,
                type,
                inventory_key: {display:(
                    <a onClick={()=>{
                        navigate(`/inventory/${inventory_key}`);
                    }}>
                        {inventory_key}
                    </a>
                ),value: inventory_key},
                inventory_key_value: inventory_key
            };
        });
        setTableData(mappedInventories);
    }

    useEffect(()=>{
        getInventories();
    },[]);

    return (
        <div className="groupinventories">
            <DataTableWithHeader 
                title="Group Inventories"
                tableDataCount={tableData.length}
                actions={actions}
                tableColumns={tableColumns}
                tableData={tableData}
                onSync={getInventories}
            >
                {/* <Button text="Create Group" variation="primary" onClick={() => { navigate('/command_center/create_group') }} /> */}
            </DataTableWithHeader>
        </div>
    )
}

export default GroupInventories;
