import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';

import AuthContext from './context/authcontext.js';
import Authenticator from './components/authenticator/authenticator.js';
import Sidebar from './components/sidebar/sidebar.js';
import Topbar from './components/topbar/topbar.js';
import Page from './components/page/page.js';

import './index.css';

//define root
const root = ReactDOM.createRoot(document.getElementById('root'));

// This is where public environment variables are stored
const environment = {
  'domain': 'https://www.stealthinventory.io',
  'api': {
    'endpoint': '/v1/api',
  },
}

// Initate the app
const App = () => {
  const [tokens,setTokens] = useState(null);
  const [user,setUser] = useState(null);
  const [isAuthenticated,setIsAuthenticated] = useState(false);
  const [refreshTimeout,setRefreshTimeout] = useState(null);

  const getUserInfo = () => {
    axios.get(environment.domain+environment.api.endpoint+'/user',
      {
        headers: {
            'AccessToken': tokens.AccessToken,
        }
      }
    )
    .then(response => {
        if (!response.data || !response.data.status) {
            console.log('Internal error. Please try again later.');
            return;
        }

        let status = response.data.status;
        let message = response.data.message;

        if (status == 'success') {
            let givenUser = response.data.user;
            setUser(givenUser);
        } else {
            console.log(message);
        }
        return;
    })
    .catch(error => {
        console.log('An unknown error has occured. Please try again.');
        return;
    });
  }

  const refreshTokens = () => {
    axios.post(environment.domain+environment.api.endpoint+'/authentication/get-refreshed-user-tokens', {
      'RefreshToken':(tokens.RefreshToken),
    })
    .then(response => {
        if (!response.data || !response.data.status) {
            console.log('Internal error. Please try again later.');
            return;
        }

        let status = response.data.status;
        let message = response.data.tokens;

        if (status == 'success') {
            let tokens = response.data.tokens;
            setTokens(tokens);
        } else {
            console.log(message);
        }
        return;
    })
    .catch(error => {
        console.log('An unknown error has occured. Please try again.');
        return;
    });
  }

  const startRefreshTimer = (expiresIn) => {
    setRefreshTimeout(setTimeout(() => {
      refreshTokens();
    }, (expiresIn-30) * 1000));
  }

  useEffect(()=>{
    if (tokens) {
      console.log(tokens);
      (tokens.ExpiresIn&&tokens.RefreshToken) ? startRefreshTimer(tokens.ExpiresIn) : setTokens(null); //maximum one time refresh for app usage up to 2 hrs
      getUserInfo();
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  },[tokens]);

  const logout = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }
    setTokens(null);
  }

  return (      
      <Authenticator 
        environment={environment} 
        setTokens={setTokens} 
        isAuthenticated={isAuthenticated}
      >
        <AuthContext.Provider
          value={
            {
              logout: logout,
              tokens: tokens,
              user: user,
            }
          }
        >
          <Router>
            <div style={{display:'flex'}}>
              <Sidebar/>
              <div style={{display:'flex',flexDirection:'column',flex:'1',maxHeight:'100vh'}}>
                <Topbar/>
                <Page environment={environment}/>
              </div>
            </div>
          </Router>
        </AuthContext.Provider>
      </Authenticator>
  );
}

// Begin render
root.render(
  //<React.StrictMode>
    <App/>
  //</React.StrictMode>
);
