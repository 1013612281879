import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import './buttonlist.css';

const ButtonList = ({ buttons, vertical }) => {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

  return (
    <div className="ButtonList" style={{ flexDirection: vertical ? 'column' : 'row' }}>
      {buttons.map((button, index) => (
        <>
            <button
                key={index}
                onClick={() => {
                    button.onClick();
                    setSelectedButtonIndex(index); // Update the selected index to the current button
                }}
                className={`button ${selectedButtonIndex === index ? 'selected' : ''}`}
                >
                <span className="button-text">{button.text}</span>
                    {selectedButtonIndex === index && (
                        <FontAwesomeIcon icon={faCheck} className="icon" />
                    )}
                </button>
            {index!=buttons.length-1&&<div className="divider"/>}
        </>
      ))}
    </div>
  );
};

export default ButtonList;
