import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import './optionlist.css';

const OptionList = ({ options }) => {
  return (
    <div className="OptionList">
      {options.map((option, index) => (
        <>
          <span className="option" key={index}>{option}</span>
        </>
      ))}
      <FontAwesomeIcon icon={faPlus} className="icon" />
    </div>
  );
};

export default OptionList;
