import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import './labeledcheckbox.css';

const LabeledCheckbox = ({title,onChange}) => {
    const uniqueId = "labeled-checkbox-"+uuidv4();
    const [isChecked, setIsChecked] = useState(false);

    useEffect(()=>{
        if (onChange) {
            onChange(isChecked);
        }
    },[isChecked])

    return (
        <div className="labeledCheckbox">
            <label htmlFor={uniqueId}>
                {title}
            </label>
            <div className={`typeable inputContainer`}>
                <input 
                    type="checkbox" 
                    checked={isChecked} 
                    onChange={() => setIsChecked(!isChecked)} 
                />
            </div>
        </div>
    );
}

export default LabeledCheckbox;