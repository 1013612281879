import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserBountyHunter, faCommentDots, faBell, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import AuthContext from '../../context/authcontext.js';
import LabeledDropdown from '../elements/labeledDropdown/labeleddropdown.js';

import './topbar.css';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';

const TopbarUser = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    
    const user = auth.user ? auth.user : [];
    const picture = user.picture ? user.picture: null;
    const name = user.name ? user.name : (user.email || <FontAwesomeIcon icon={faSpinner} spin />);
    const role = user.role ? user.role : '';

    return (
        <div className="user">
            <div onClick={()=>{navigate('/');auth.logout();}} className="picture">
                {
                    picture ? (
                        <img alt="" src={picture}/>
                    ) : (
                        <FontAwesomeIcon className="placeholder" icon={faUserBountyHunter} />
                    )
                }
            </div>
            <div className="info">
                <span className="name">{name}</span>
                <span className="role">{role}</span>
            </div>
        </div>
    )
}

const TopbarNotifications = () => {
    return (
        <div className="notifications">
            <FontAwesomeIcon icon={faCommentDots} />
            <FontAwesomeIcon icon={faBell} />
        </div>
    )
}

const GroupSelection = () => {
    return (
        <div style={{width: '300px'}}>
            <LabeledDropdown
                placeholder="Select a group..."
                options={[]}
                value={''}
                icon={faLayerGroup}
                onChange={()=>{}} 
            />
        </div>
    )
}

const Topbar = () => {
    return (
        <div className="topbar">
            <div className="left">
                <GroupSelection/>
            </div>
            <div className="right">
                <TopbarNotifications/>
                <TopbarUser/>
            </div>
        </div>
    );
}

export default Topbar;