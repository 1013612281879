import './creategroup.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';

import Section from '../../../components/elements/section/section.js';
import LabeledInput from '../../../components/elements/labeledInput/labeledinput.js';
import Button from '../../../components/elements/button/button.js';

import AuthContext from '../../../context/authcontext.js';

import { Group } from '../../../api';

const NameAndTags = ({returnTo}) => {
    const [name,setName] = useState('');

    useEffect(()=>{
        returnTo(name);
    },[name]);

    return (
        <Section
            title = "Name and Tags"
        >
            <LabeledInput
                title="Name" 
                placeholder="e.g. Coast Technology Group" 
                icon={faLayerGroup}
                onChange={(event) => setName(event.target.value)} 
            />
        </Section>
    )
}

const CreateGroup = ({environment}) => {
    const [name,setName] = useState(null);
    const navigate = useNavigate();

    //authentication
    const auth = useContext(AuthContext);
    const group = new Group(auth,environment);

    const handleGroupCreation = async () => {
        const group_key = await group.post(name);
        navigate(`/group/${group_key}`);
    }

    return (
        <div className="creategroup">
            <div className="setupContainer">
                <header>
                    <h1>Create a Group <span className="clickable info">Info</span></h1>
                    <h2>Stealth Inventory allows you to set up instance groups, curated collections designed to categorize dealership details and oversee inventory feed clusters.</h2>
                </header>
                <NameAndTags returnTo={setName}/>
            </div>
            <div className="summaryContainer">
                <Section title="Summary">
                    <Button text="Launch Group" onClick={()=>{
                        handleGroupCreation();
                    }} />
                </Section>
            </div>
        </div>
    )
}
//407 St Tammany St, Madisonville, LA 70447
export default CreateGroup;